import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
//
import useQueryParams from "components/Hooks/useQueryParams";
import PaymentTab from "./Payment";
import PointTab from "./Point";
import MemberTab from "./Members";

const CmsHubMedia = () => {
  const { t } = useTranslation();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const { clearQueryParams } = useQueryParams();
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const tabs = [
    {
      id: "1",
      label: t("membership.membership"),
      content: <MemberTab/>,
      route: "list",
    },
    {
      id: "2",
      label: t("membership.payment_details"),
      content: <PaymentTab />,
      route: "payment",
    },
    {
      id: "3",
      label: t("membership.point_details"),
      content: <PointTab />,
      route: "point",
    },
  ];

  // useEffect(() => {
  //   const pathname = location.pathname;
  //   const activeTab = tabs.find((tab) => pathname.includes(tab.route));
  //   setcustomActiveTab(activeTab?.id || "1");
  // }, [location.pathname]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb
            title={
              tabs.find((tab) => location?.pathname?.includes(tab.route))?.label
            }
          /> */}
          <Nav tabs className="nav nav-tabs nav-tabs-custom mb-3">
            {tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === tab.id,
                  })}
                  onClick={() => {
                    toggleCustom(tab.id);
                    clearQueryParams();
                    // navigate("/membership/" + tab.route);
                  }}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={customActiveTab} className="text-muted">
            {tabs.map((tab) => (
              <TabPane tabId={tab.id} key={tab.id} id={`tab-${tab.id}`}>
                {customActiveTab === tab.id && tab.content}
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CmsHubMedia;
