import React, { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

const TooltipCustom = ({
  title = "",
  children,
  className = "",
  style = {},
}: any) => {
  const ref = useRef<any>(null);
  return (
    <React.Fragment>
      <li ref={ref} className={className} style={style}>
        {children}
      </li>
      <UncontrolledTooltip target={ref}>{title}</UncontrolledTooltip>
    </React.Fragment>
  );
};

export default TooltipCustom;
