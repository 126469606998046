import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// actions
import { StringParam, useQueryParam } from "use-query-params";
import logoDark from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { catchAsync } from "helpers/general";
import { removeEmptyFields } from "utils";
import authApi from "api/authApi";
import { useSelector } from "react-redux";

const SetNewPassword = (props: any) => {
  const { t } = useTranslation();
  const user: any = useSelector((state: any) => state?.Login);
  const [redirectFrom] = useQueryParam("redirectFrom", StringParam);
  // Inside your component
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(""),
    }),
    onSubmit: async (values) => {
      const [err] = await catchAsync(authApi.changePassword(removeEmptyFields(values)), setLoader);
      if (err) {
        console.log(err);
        setError(err);
        return;
      }
      navigate(redirectFrom ? redirectFrom : "/media");
    },
  });

  useEffect(() => {
    let authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      const localUser = JSON.parse(authUser);
      if(!localUser?.admin?.is_need_change_pwd) navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])

  document.title = `Newshub - ${t("auth.set_new_password")}`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                  <div>
                    <Link to='/' className='d-inline-block auth-logo'>
                      <img src={logoDark} alt='' height='30' />
                    </Link>
                  </div>
                  <p className='mt-3 fs-15 fw-medium'>{t("auth.super_admin_dashboard")}</p>
                </div>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card
                  className='mt-4'
                  style={{ boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)" }}
                >
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>{t("auth.set_new_password")}</h5>
                      <p className='text-muted'>
                        {t("Create new password to continue to Supper Admin System.")}
                      </p>
                    </div>
                    <div className='p-2 mt-4'>
                      {!!error && (
                        <Alert color='danger '>
                          {error?.errors?.password
                            ? error?.errors?.password
                            : JSON.stringify(error)}
                        </Alert>
                      )}
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action='#'
                      >
                        <div className='mb-3'>
                          <Label className='form-label' htmlFor='password-input'>
                            {t("auth.password.label")}
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className='form-control pe-5'
                              placeholder={t("auth.password.placeholder")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password
                                  ? true
                                  : false
                              }
                              maxLength={50}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              onClick={() => setPasswordShow(!passwordShow)}
                              type='button'
                              id='password-addon'
                            >
                              <i className='ri-eye-fill align-middle'></i>
                            </button>
                          </div>
                        </div>

                        <div className='mt-4'>
                          <Button
                            color='success'
                            disabled={loader || !validation.isValid || !validation.dirty}
                            className='btn btn-success w-100'
                            type='submit'
                          >
                            {loader && (
                              <Spinner size='sm' className='me-2'>
                                {t("Loading") + ""}
                              </Spinner>
                            )}
                            {t("auth.btn.change_password")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(SetNewPassword);
