import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import { catchAsync, formatNumber } from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import ModalReporter from "./ModalDetail";

const ReporterTab = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [modal, setModal] = useState({
    isOpen: false,
    id: 0,
  });
  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("cms_media.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => <div>{pagination.total - pagination.page_size * (pagination.page - 1) - (row?.index || 0)}</div>,
      },
      {
        Header: t("cms_media.media_name"),
        accessor: "company_name",
        filterable: false,
        sortable: false,
      },
      {
        Header: t("cms_media.reporter_name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => (
          <div
            style={{
              textUnderlineOffset: "2px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setModal({
              isOpen: true,
              id: row?.original?.id,
            })}
          >
            {row.original?.name || ""}
          </div>
        ),
      },
      {
        Header: t("cms_media.department"),
        accessor: "department_name",
        filterable: false,
        sortable: false,
      },
      {
        Header: t("cms_media.published_article"),
        accessor: "published_article",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => <div className="text-center">{formatNumber(row.original?.total_published)}</div>,
      },
      {
        Header: t("cms_media.average_daily_published_article"),
        accessor: "average_daily_published_article",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => <div className="text-center">{row.original?.daily_avg_published}</div>,
      },
      {
        Header: t("cms_media.average_pv"),
        accessor: "average_pv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => <div className="text-center">{formatNumber(row.original?.avg_pv)}</div>,
      },
      {
        Header: t("cms_media.average_uv"),
        accessor: "average_uv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => <div className="text-center">{formatNumber(row.original?.avg_uv)}</div>,
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    const [err, result] = await catchAsync(
      cmsHubMediaApi.cmshubReporters(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    const startDateTmp = startDate ? moment(startDate).format("YYYY-MM-DD") : moment().subtract(7, "days").format("YYYY-MM-DD")
    const endDateTmp = endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDateTmp,
      end_date: endDateTmp
    });
    setDateSearch([moment(startDateTmp).toDate(), moment(endDateTmp).toDate()]);
  };

  const handleChangePage = useCallback((page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  }, []);

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
    let params = { ...queryParams };
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
            <div className="w-100">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div
                    className="d-flex align-items-center date-picker-wrapper-custom"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">{t('cms_media.period')}</div>
                    <div style={{ width: "300px" }}>
                      <DatePickerCustom
                        placeholder={`${t("A From Range")} - ${t(
                          "A To Range"
                        )}`}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        disabled={false}
                        isClearable={true}
                        onChangePicker={handleChangePicker}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">{t('cms_media.search')}</div>
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder={`${t("cms_media.please_enter_the_media_name_and_reporter_name")}...`}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchCategory();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                >
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSearchCategory}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
      <ModalReporter isOpen={modal.isOpen} onClose={()=>setModal({isOpen: false, id: 0})} id={modal.id}  />
    </React.Fragment>
  );
};

export default ReporterTab;
