import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = "/companies";
const companyApi = {
  allCompanies(): Promise<
    AxiosResponse<ResponseData<any[]> & PaginationResponse, any>
  > {
    const url = `${path}/all`;
    return api.get(url);
  },
};
export default companyApi;
