import { APIClient } from "./axiosClient";
import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/cmshub";
const cmsHubStatistics = {
  getArticles(dataForm: QueryParams) {
    return api.get(`${path}/statistics/articles`, dataForm);
  },
  getArticlesLogs(dataForm: QueryParams) {
    return api.get(`${path}/statistics/articles/logs`, dataForm);
  },
  getArticlesSummaries(dataForm: QueryParams) {
    return api.get(`${path}/statistics/articles/summaries`, dataForm);
  },
  getVisit(dataForm: QueryParams) {
    return api.get(`${path}/statistics/visit`, dataForm);
  },
  getArticlesKeyword(dataForm: QueryParams) {
    return api.get(`${path}/statistics/keywords`, dataForm);
  },
  getArticlesKeywordInflow(dataForm: QueryParams) {
    return api.get(`${path}/statistics/keywords/traffic`, dataForm);
  },
};

export default cmsHubStatistics;
