import { AxiosResponse } from "axios";
import { IAccount, ResponseData } from "./types/_account";
import { QueryParams } from "components/Hooks/useQueryParams";
import { IPagination } from "./types/general";
import { InterceptorClient } from "./interceptorClient";
const api = new InterceptorClient();

const path = "/admin-account";

const accountApi = {
  accounts(
    params: QueryParams
  ): Promise<AxiosResponse<ResponseData<IAccount[]> & IPagination, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },

  getUserByID(id: string): Promise<AxiosResponse<IAccount & IPagination, any>> {
    const url = `${path}/${id}`;
    return api.get(url);
  },

  createAccount(body: any): Promise<AxiosResponse<IAccount & IPagination, any>> {
    const url = `${path}`;
    return api.post(url, body);
  },

  updateAccount(id: string, body: any): Promise<AxiosResponse<IAccount & IPagination, any>> {
    const url = `${path}/${id}`;
    return api.put(url, body);
  },

  resetPassword(id: string, body: any): Promise<AxiosResponse<IAccount & IPagination, any>> {
    const url = `${path}/${id}/reset-password`;
    return api.post(url, body);
  },
};

export default accountApi;
