import { InterceptorClient } from "./interceptorClient";
const api = new InterceptorClient();

const path = "/auth";
const authApi = {
  login(data: any) {
    const url = `${path}/login`;
    return api.post(url, data);
  },
  refresh(data: any) {
    const url = `${path}/refresh`;
    return api.post(url, data);
  },
  logout() {
    const url = `${path}/logout`;
    return api.post(url, {});
  },
  permissions(params: any = {}) {
    const url = `/permissions/current`;
    return api.get(url, params);
  },
  profile(params: any = {}) {
    const url = `/profile`;
    return api.get(url, params);
  },
  changePassword(data: any) {
    const url = `/profile/change-pwd`;
    return api.post(url, data);
  },
  updateProfile(data: any) {
    const url = `/profile`;
    return api.put(url, data);
  },
};
export default authApi;
