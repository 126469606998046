import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";
import Settings from "../pages/Pages/Profile/Settings/Settings";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import CampaignListByKeywordAnalyzing from "pages/Campaign/KeywordAnalyzingNews/CampaignList";
import CampaignManual from "pages/Campaign/KeywordAnalyzingNews/CampaignManual";
import CampaignPopular from "pages/Campaign/KeywordAnalyzingNews/CampaignPopular";
import NewsListByKeywordAnalyzing from "pages/Campaign/KeywordAnalyzingNews/News";
import CampaignListByTrendRanking from "pages/Campaign/TrendRankingNews/CampaignList";
import CampaignNormal from "pages/Campaign/TrendRankingNews/CampaignNormal";
import NewsListByTrendRanking from "pages/Campaign/TrendRankingNews/News";
import CarCategory from "pages/Car/Category";
import CarKeyword from "pages/Car/Keyword";
import CurrencyCategory from "pages/ExchangeRate/Category";
import Currency from "pages/ExchangeRate/Currency";
import ExchangeRateKeyword from "pages/ExchangeRate/Keyword";
import Media from "pages/Media/Media";
import NotificationList from "pages/Media/Notification";
import Settlement from "pages/Media/Settlement";
import MovieCategory from "pages/Movie/Category";
import MovieKeyword from "pages/Movie/Keyword";
import NaverCategory from "pages/Naver/Category";
import NaverKeyword from "pages/Naver/Keyword";
import SportCategory from "pages/Sport/Category";
import SportKeyword from "pages/Sport/Keyword";
import SportSchedule from "pages/Sport/Schedule";
import CampaignStatistic from "pages/Statistic/CampaignStatistic";
import CategoryStatistic from "pages/Statistic/CategoryStatistic";
import KeywordInflow from "pages/Statistic/KeywordInflow";
import KeywordStatistic from "pages/Statistic/KeywordStatistic";
import MediaLogList from "pages/Statistic/MediaLog";
import NewsLog from "pages/Statistic/NewsLog";
import NewsStatistic from "pages/Statistic/NewsStatistic";
import SiteStatistic from "pages/Statistic/SiteStatistic";
import VisitorStatistic from "pages/Statistic/VisitorStatistic";
import StockCategory from "pages/Stock/Category";
import StockKeyword from "pages/Stock/Keyword";
import InfoGraphic from "pages/Template/InfoGraphic";
import TemplateImage from "pages/Template/TemplateImage";
import TemplateListNewsContent from "pages/Template/TemplateListNewsContent";
import TemplateListNewsTitle from "pages/Template/TemplateListNewsTitle";
import VariableDefinition from "pages/Template/VariableDefinition";
import TrendIndexCategory from "pages/TrendIndex/Category";
import TrendIndexKeyword from "pages/TrendIndex/Keyword";
import Profile from "pages/User/Profile";
import RoleList from "pages/User/Role";
import UserList from "pages/User/User";

import CampaignFlowedKeyword from "pages/Campaign/FlowedKeyword";
import CampaignLottoCreate from "pages/Campaign/TrendRankingNews/CampaignLotto";
import CmsHubMedia from "pages/CmsHub";
import CoinKeyword from "pages/Coin/Keyword";
import Configuration from "pages/Configuration";
import CampaignStatisticFromLab from "pages/Lab/CampaignStatistic";
import CategoryStatisticFromLab from "pages/Lab/CategoryStatistic";
import FlowedKeywordAnalysis from "pages/Lab/FlowedKeywordAnalysis";
import NewsStatisticFromLab from "pages/Lab/NewsStatistic";
import AdminActivityLogs from "pages/Logging/AdminActivityLogs";
import LottoSchedule from "pages/Lotto/Schedule";
import AdsAccountList from "pages/Media/AdsAccount/History";
import MediaRanking from "pages/Statistic/MediaRanking";
import CmsHubMembership from "pages/Membership";
import MediaRevenueStatistics from "pages/Statistic/MediaRevenueStatistics";
import RevenueLogList from "pages/Statistic/RevenueLog";
import RevenueStatistics from "pages/Statistic/RevenueStatistics";
//
import Account from "pages/Account";
import CreateAndUpdateAccount from "pages/Account/CreateAndUpdate";
import SetNewPassword from "pages/Authentication/SetNewPassword";
import Revenue from "pages/Media/Revenue";
import ArticleStatistics from "pages/ArticleManagement/ArticleStatistics";
import Statistics from "pages/CmsHub/Statistics";
import UsageExceeded from "pages/Membership/UsageExceeded";
import ArticleList from "pages/ArticleManagement/ArticleList";
import InquiryList from "pages/Inquiry";
import InquiryHomePage from "pages/Inquiry/HomePage";


const ROUTES = {
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  PROFILE_SETTING: "/pages-profile-settings",

  NAVER_CATEGORY: "/keyword/naver-category",
  NAVER_KEYWORD: "/keyword/naver-keyword",

  CAR_CATEGORY: "/keyword/car-category",
  CAR_KEYWORD: "/keyword/car-keyword",

  MOVIE_CATEGORY: "/keyword/movie-category",
  MOVIE_KEYWORD: "/keyword/movie-keyword",

  EXCHANGE_RATE_CATEGORY: "/keyword/exchange-rate-category",
  EXCHANGE_RATE: "/keyword/exchange-rate",
  EXCHANGE_CURRENCY: "/exchange/currency",

  COIN: "/keyword/coin",

  STOCK_CATEGORY: "/keyword/stock-category",
  STOCK: "/keyword/stock",

  SPORT_CATEGORY: "/keyword/sport-category",
  SPORT: "/keyword/sport",
  SPORT_SCHEDULE: "/keyword/sport-schedule",

  LOTTO_SCHEDULE: "/keyword/lotto-schedule",

  TREND_INDEX_CATEGORY: "/keyword/trend-index-category",
  TREND_INDEX: "/keyword/trend-index",

  CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN: "/campaign/trend-ranking-news/create",
  CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_SPORT:
    "/campaign/trend-ranking-news/create-campaign-sport",
  CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_LOTTO:
    "/campaign/trend-ranking-news/create-campaign-lotto",
  CAMPAIGN_TREND_RANKING_LIST_CAMPAIGN: "/campaign/trend-ranking-news/list",
  CAMPAIGN_TREND_RANKING_LIST_NEWS: "/campaign/trend-ranking-news/news",

  CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_POPULAR:
    "/campaign/keyword-analyzing-news/create-campaign-popular",
  CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL:
    "/campaign/keyword-analyzing-news/create-campaign-manual",
  CAMPAIGN_FLOWED_KEYWORD_CREATE_CAMPAIGN:
    "/campaign/keyword-analyzing-news/create-campaign-core-keyword",
  CAMPAIGN_KEYWORD_ANALYZING_LIST_CAMPAIGN:
    "/campaign/keyword-analyzing-news/list",
  CAMPAIGN_KEYWORD_ANALYZING_LIST_NEWS: "/campaign/keyword-analyzing-news/news",

  TEMPLATE_TITLE: "/template/news-title",
  TEMPLATE_CONTENT: "/template/news-content",
  TEMPLATE_VARIABLE_DEFINITION: "/template/variable-definition",
  TEMPLATE_INFO_GRAPHIC: "/template/info-graphic",
  TEMPLATE_IMAGE: "/template/image",

  MEDIA: "/media",
  MEDIA_REVENUE: "/media/revenue",

  REVENUE: "/revenue",

  NOTIFICATION: "/notification",

  ADS_ACCOUNT: "/ads-account",

  SETTLEMENT: "/settlement",

  STATISTICS_NEWS_LOG: "/statistics/news-log",
  STATISTICS_NEWS_STATISTICS: "/statistics/news-statistics",
  STATISTICS_MEDIA_LOG: "/statistics/media-log",
  STATISTICS_MEDIA_STATISTICS: "/statistics/media-statistics",
  STATISTICS_KEYWORD_STATISTICS: "/statistics/keyword-statistics",
  STATISTICS_KEYWORD_INFLOW: "/statistics/keyword-inflow",
  STATISTICS_KEYWORD_ANALYSIS: "/lab/keyword-analysis",
  STATISTICS_CAMPAIGN_STATISTICS: "/statistics/campaign-statistics",
  STATISTICS_CATEGORY_STATISTICS: "/statistics/category-statistics",
  STATISTICS_VISITOR_STATISTICS: "/statistics/visitor-statistics",
  STATISTICS_REVENUE_LOG: "/statistics/revenue-log",
  STATISTICS_REVENUE_STATISTICS: "/lab/revenue-statistics",
  STATISTICS_MEDIA_REVENUE_STATISTICS: "/lab/media-revenue-statistics",
  STATISTICS_FLOWED_KEYWORD_ANALYSIS: "/lab/core-keyword-analysis",
  STATISTICS_MEDIA_RANKING: "/lab/media-ranking",

  USER_LIST: "/user/list",
  USER_ROLE: "/user/role",

  LAB_NEWS_STATISTICS: "/lab/news-statistics",
  LAB_CAMPAIGN_STATISTICS: "/lab/campaign-statistics",
  LAB_CATEGORY_STATISTICS: "/lab/category-statistics",

  CONFIGURATION: "/configuration",

  ADMIN_ACTIVITY_LOGS: "/logging/admin-activity-logs",

  //
  CMS_HUB_MEDIA_MANAGEMENT: "/cms-hub/media",
  CMS_HUB_MEMBERSHIP: "/cms-hub/membership",
  CMS_HUB_MEMBERSHIP_USAGE_EXCEEDED: "/cms-hub/membership/usage-exceeded",
  CMS_HUB_STATISTICS: '/cms-hub/statistics',
  //

  ACCOUNT: "/account",
  ACCOUNT_CREATE: "/account/create",
  ACCOUNT_UPDATE: "/account/update",
  SET_NEW_PASSWORD: "/set-new-password",
  // INQUIRY: '/inquiry',
  // INQUIRY_HOMEPAGE: '/inquiry/homepage',
  INQUIRY_MANAGEMENT: {
    INQUIRY: '/inquiry',
    INQUIRY_HOMEPAGE: '/inquiry/homepage',
  },

  // Articles Management
  ARTICLES_MANAGEMENT: {
    ARTICLE_STATISTICS: "/articles-management/article-statistics",
    ARTICLE_LIST: "/articles-management/article-list",
  },
}

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },

  { path: ROUTES.NAVER_CATEGORY, component: <NaverCategory /> },

  { path: ROUTES.NAVER_KEYWORD, component: <NaverKeyword /> },

  { path: ROUTES.CAR_CATEGORY, component: <CarCategory /> },

  { path: ROUTES.CAR_KEYWORD, component: <CarKeyword /> },

  { path: ROUTES.MOVIE_CATEGORY, component: <MovieCategory /> },

  { path: ROUTES.MOVIE_KEYWORD, component: <MovieKeyword /> },

  { path: ROUTES.EXCHANGE_RATE_CATEGORY, component: <CurrencyCategory /> },

  { path: ROUTES.EXCHANGE_RATE, component: <ExchangeRateKeyword /> },

  { path: ROUTES.COIN, component: <CoinKeyword /> },

  { path: ROUTES.STOCK_CATEGORY, component: <StockCategory /> },

  { path: ROUTES.STOCK, component: <StockKeyword /> },

  { path: ROUTES.SPORT_CATEGORY, component: <SportCategory /> },

  // { path: ROUTES.SPORT, component: <SportKeyword /> },

  { path: ROUTES.SPORT_SCHEDULE, component: <SportSchedule /> },

  { path: ROUTES.LOTTO_SCHEDULE, component: <LottoSchedule /> },

  { path: ROUTES.TREND_INDEX_CATEGORY, component: <TrendIndexCategory /> },

  { path: ROUTES.TREND_INDEX, component: <TrendIndexKeyword /> },

  {
    path: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN,
    component: <CampaignNormal />,
  },

  {
    path: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_SPORT,
    component: <SportKeyword />,
  }, // <CampaignSportCreate />

  {
    path: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_LOTTO,
    component: <CampaignLottoCreate />,
  },

  {
    path: ROUTES.CAMPAIGN_TREND_RANKING_LIST_CAMPAIGN,
    component: <CampaignListByTrendRanking />,
  },

  {
    path: ROUTES.CAMPAIGN_TREND_RANKING_LIST_NEWS,
    component: <NewsListByTrendRanking />,
  },

  {
    path: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_POPULAR,
    component: <CampaignPopular />,
  },

  {
    path: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL,
    component: <CampaignManual />,
  },

  {
    path: ROUTES.CAMPAIGN_FLOWED_KEYWORD_CREATE_CAMPAIGN,
    component: <CampaignFlowedKeyword />,
  },

  {
    path: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_LIST_CAMPAIGN,
    component: <CampaignListByKeywordAnalyzing />,
  },

  {
    path: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_LIST_NEWS,
    component: <NewsListByKeywordAnalyzing />,
  },

  { path: ROUTES.TEMPLATE_TITLE, component: <TemplateListNewsTitle /> },

  { path: ROUTES.TEMPLATE_CONTENT, component: <TemplateListNewsContent /> },

  {
    path: ROUTES.TEMPLATE_VARIABLE_DEFINITION,
    component: <VariableDefinition />,
  },

  { path: ROUTES.TEMPLATE_INFO_GRAPHIC, component: <InfoGraphic /> },

  { path: ROUTES.TEMPLATE_IMAGE, component: <TemplateImage /> },

  { path: ROUTES.MEDIA, component: <Media /> },

  { path: ROUTES.MEDIA_REVENUE, component: <Revenue /> },

  { path: ROUTES.NOTIFICATION, component: <NotificationList /> },

  { path: ROUTES.ADS_ACCOUNT, component: <AdsAccountList /> },

  { path: ROUTES.SETTLEMENT, component: <Settlement /> },

  { path: ROUTES.EXCHANGE_CURRENCY, component: <Currency /> },

  { path: ROUTES.STATISTICS_NEWS_LOG, component: <NewsLog /> },

  { path: ROUTES.STATISTICS_NEWS_STATISTICS, component: <NewsStatistic /> },

  { path: ROUTES.STATISTICS_MEDIA_LOG, component: <MediaLogList /> },

  { path: ROUTES.STATISTICS_MEDIA_STATISTICS, component: <SiteStatistic /> },

  {
    path: ROUTES.STATISTICS_KEYWORD_STATISTICS,
    component: <KeywordStatistic />,
  },

  { path: ROUTES.STATISTICS_KEYWORD_INFLOW, component: <KeywordInflow /> },

  {
    path: ROUTES.STATISTICS_FLOWED_KEYWORD_ANALYSIS,
    component: <FlowedKeywordAnalysis />,
  },

  {
    path: ROUTES.STATISTICS_CAMPAIGN_STATISTICS,
    component: <CampaignStatistic />,
  },

  {
    path: ROUTES.STATISTICS_CATEGORY_STATISTICS,
    component: <CategoryStatistic />,
  },

  {
    path: ROUTES.STATISTICS_VISITOR_STATISTICS,
    component: <VisitorStatistic />,
  },

  { path: ROUTES.STATISTICS_REVENUE_LOG, component: <RevenueLogList /> },

  {
    path: ROUTES.STATISTICS_REVENUE_STATISTICS,
    component: <RevenueStatistics />,
  },

  { path: ROUTES.STATISTICS_MEDIA_RANKING, component: <MediaRanking /> },

  {
    path: ROUTES.STATISTICS_MEDIA_REVENUE_STATISTICS,
    component: <MediaRevenueStatistics />,
  },

  { path: ROUTES.USER_LIST, component: <UserList /> },

  { path: ROUTES.USER_ROLE, component: <RoleList /> },

  { path: ROUTES.PROFILE, component: <Profile /> },

  { path: ROUTES.LAB_NEWS_STATISTICS, component: <NewsStatisticFromLab /> },

  {
    path: ROUTES.LAB_CAMPAIGN_STATISTICS,
    component: <CampaignStatisticFromLab />,
  },

  {
    path: ROUTES.LAB_CATEGORY_STATISTICS,
    component: <CategoryStatisticFromLab />,
  },

  { path: ROUTES.CONFIGURATION, component: <Configuration /> },

  { path: ROUTES.ADMIN_ACTIVITY_LOGS, component: <AdminActivityLogs /> },

  // CMS-HUB
  { path: ROUTES.CMS_HUB_MEDIA_MANAGEMENT, component: <CmsHubMedia /> },

  { path: ROUTES.CMS_HUB_STATISTICS, component: <Statistics /> },

  { path: ROUTES.ACCOUNT, component: <Account /> },

  { path: ROUTES.ACCOUNT_CREATE, component: <CreateAndUpdateAccount /> },

  { path: ROUTES.ACCOUNT_UPDATE, component: <CreateAndUpdateAccount /> },

  { path: ROUTES.CMS_HUB_MEMBERSHIP, component: <CmsHubMembership /> },
  { path: ROUTES.CMS_HUB_MEMBERSHIP_USAGE_EXCEEDED, component: <UsageExceeded /> },
  // Articles-Management
  {
    path: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_STATISTICS,
    component: <ArticleList />,
  },
  {
    path: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_LIST,
    component: <ArticleStatistics />,
  },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY, component: <InquiryList /> },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE, component: <InquiryHomePage /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/media" />,
  },
  { path: "*", component: <Navigate to="/media" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: ROUTES.SET_NEW_PASSWORD, component: <SetNewPassword /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES };
