//---Handle Access Token---
const ACCESS_TOKEN = "access_token"
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN)
export const setAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN, token)
export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN)

// Language
const LANGUAGE = 'i18nextLng'
export const getLanguage = () => localStorage.getItem(LANGUAGE)

// User
const AUTH = 'authUser'
export const getAuth = () => localStorage.getItem(AUTH)

const REFRESH_TOKEN = "refresh_token"
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN)
export const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN, token)
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN)
