import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Input, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
import isURL from "validator/lib/isURL";
import {
  IGradeArticle,
  IPopupDTO,
  Ireporter,
  IresCmsPopup,
} from "api/types/_cmsHub";
import { useFormik } from "formik";
import moment from "moment";
import {
  catchAsync,
  formatNumber,
  formatWithKoreanAmPm,
} from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import ControlBox from "../Components/ControlBox";
import closeWhiteSvg from "../../../assets/images/cmshub/delete.svg";
import DateTimePickerCustom from "../Components/DateTimePicker";
import ButtonMediaAccess from "../Components/ButtonGotoMedia";
import { useModal } from "context/confirm.context";

interface Props {
  isOpen: boolean;
  id: number;
  onClose: () => void;
}
const ModalReporter = (props: Props) => {
  const { t } = useTranslation();
  const GRADE = {
    HEADLINE: 3,
    IMPORTANT: 2,
    NORMAL: 1,
  };
  const [detail, setDetail] = useState({} as Ireporter);

  const getPopup = async (id: number) => {
    const [error, result] = await catchAsync(
      cmsHubMediaApi.cmshubDetailReporter(props.id)
    );
    if (error) {
      console.log(error);
      return;
    }
    setDetail(result.data);
  };

  const gradeArticles = useMemo(() => {
    return detail?.grade_articles?.reduce((acc: any, item) => {
      for (const [key, value] of Object.entries(GRADE)) {
        if (item.grade === value) {
          acc[key] = item.total;
          break;
        }
      }
      return acc;
    }, {});
  }, [detail]);

  useEffect(() => {
    if (props.id) {
      getPopup(props.id);
    }
  }, [props.id, props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered={true}
      scrollable={false}
      style={{ width: "100%", minWidth: '900px' }}
    >
      <div className="d-flex justify-content-between pt-3 pb-2 px-3 items-center">
        <div></div>
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("cms_media.detailed_inquiry")}
        </div>
        <div onClick={() => props.onClose()}>
          <i
            className="ri-close-line"
            style={{ fontSize: "20px", cursor: "pointer" }}
          ></i>
        </div>
      </div>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2 align-items-center w-20">
            <div className="fw-bold whitespace-nowrap">{t("cms_media.media_name")}</div>
            <div className="ellipsis-1" style={{ maxWidth: '150px' }}>{detail.company_name}</div>
          </div>
          <div className="d-flex gap-2 align-items-center w-20">
            <div className="fw-bold whitespace-nowrap">{t("cms_media.reporter_name")}</div>
            <div className="ellipsis-1" style={{ maxWidth: '150px' }}>{detail.name}</div>
          </div>
          <div className="d-flex gap-2 align-items-center w-20">
            <div className="fw-bold whitespace-nowrap">{t("cms_media.department")}</div>
            <div className="ellipsis-1" style={{ maxWidth: '150px' }}>{detail.department_name || "-"}</div>
          </div>
          <div className="d-flex gap-2 align-items-center w-20">
            <div className="fw-bold whitespace-nowrap">{t("cms_media.phone_number")}</div>
            <div className="ellipsis-1" style={{ maxWidth: '150px' }}>{detail.phone_number || "-"}</div>
          </div>
        </div>
        <div className="d-flex w-100 mt-3 gap-2">
          <Card className="p-3 w-100">
            <div style={{ fontSize: "16px", fontWeight: "700" }}>
              {t("cms_media.article_statistics")}
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <div className="fw-bold">
                  {t("cms_media.total_published_articles")}
                </div>
                <div className="fw-bold">
                  {t("cms_media.average_daily_published_article")}
                </div>
                <div className="fw-bold">{t("cms_media.average_pv")}</div>
                <div className="fw-bold">{t("cms_media.average_uv")}</div>
              </div>
              <div>
                <div>
                  {formatNumber(
                    detail?.statistic_article?.total_published || 0
                  )}
                </div>
                <div>{detail?.statistic_article?.daily_avg_published || 0}</div>
                <div>
                  {formatNumber(detail?.statistic_article?.avg_pv || 0)}
                </div>
                <div>
                  {formatNumber(detail?.statistic_article?.avg_uv || 0)}
                </div>
              </div>
            </div>
          </Card>
          <Card className="p-3 w-100">
            <div style={{ fontSize: "16px", fontWeight: "700" }}>
              {t("cms_media.number_of_publications_by_grade")}
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <div className="fw-bold">{t("cms_media.headline")}</div>
                <div className="fw-bold">
                  {t("cms_media.important_article")}
                </div>
                <div className="fw-bold">{t("cms_media.general_article")}</div>
              </div>
              <div>
                <div>{formatNumber(gradeArticles?.["HEADLINE"] || 0)}</div>
                <div>{formatNumber(gradeArticles?.["IMPORTANT"] || 0)}</div>
                <div>{formatNumber(gradeArticles?.["NORMAL"] || 0)}</div>
              </div>
            </div>
          </Card>
        </div>
        <div className="d-flex w-100 mt-3 gap-2">
          <Card className="p-3 w-100">
            <div className="mb-2" style={{ fontSize: "16px", fontWeight: "700" }}>
              {t("cms_media.number_of_publications_by_section")}
            </div>
            {detail?.top_categories?.map((item) => (
              <div key={item.id} className="d-flex justify-content-between gap-4">
                <div className="fw-bold ellipsis-1">{item?.name}</div>
                <div>{formatNumber(item?.total_articles || 0)}</div>
              </div>
            ))}
          </Card>
          <Card className="p-3 w-100">
            <div className="mb-2" style={{ fontSize: "16px", fontWeight: "700" }}>
              {t("cms_media.most_viewed_articles")}
            </div>
            {detail?.top_articles?.map((item) => (
              <div key={item.id} className="d-flex justify-content-between gap-4">
                <div className="fw-bold ellipsis-1" style={{ textDecoration: 'underline', textUnderlineOffset: '2px', cursor: 'pointer' }}>
                  <a href={detail?.domain + '/post/' + item?.slug} style={{ color: '#222' }} target="_blank">{item?.title}</a>
                </div>
                <div>{formatNumber(item?.page_view || 0)}</div>
              </div>
            ))}
          </Card>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalReporter;
