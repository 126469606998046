import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../../components/Common/TableContainer";
import { catchAsync, formatNumber } from "helpers/general";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import CountUp from "react-countup";
import LabelWrapper from "components/Common/LabelWrapper";
import cmsHubStatistics from "api/cmsHubStatistics";

const Article = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    publish_start_date: moment().format("YYYY-MM-DD"),
    publish_end_date: moment().format("YYYY-MM-DD"),
    search: "",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const defaultRangeDatePublish = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [datePublishSearch, setDatePublishSearch] = useState<string[]>(
    defaultRangeDatePublish
  );
  const [publish_start_date, publish_end_date] = datePublishSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("cms_statistics.STT"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.page_size * (pagination.page - 1) +
              (row?.index + 1 || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_statistics.title"),
        accessor: "title",
        Cell: (cell: any) => (
          <div
            onClick={() =>
              window.open(
                `${cell?.row?.original?.domain}/post/${cell?.row?.original?.slug}`
              )
            }
            className="text-primary cursor-pointer"
          >
            {cell?.value}
          </div>
        ),
      },
      {
        Header: "PV",
        accessor: "pv",
        description: "PV",
        thClass: "text-center text-nowrap",
        Cell: (cell: any) => (
          <div className="text-center">
            {cell.value ? formatNumber(cell.value) : "-"}
          </div>
        ),
      },
      {
        Header: "UV",
        accessor: "uv",
        description: "UV",
        thClass: "text-center text-nowrap",
        Cell: (cell: any) => (
          <div className="text-center">
            {cell.value ? formatNumber(cell.value) : "-"}
          </div>
        ),
      },
      {
        Header: t("cms_statistics.media"),
        accessor: "company_name",
        Cell: (cell: any) => (
          <div
            onClick={() => window.open(cell?.row?.original?.domain)}
            className="cursor-pointer"
          >
            <p className="m-0">{cell?.value}</p>
            <p className="m-0 text-primary">{cell?.row?.original?.domain}</p>
          </div>
        ),
      },
      {
        Header: t("cms_statistics.TransmissionDate"),
        accessor: "published_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">
            {cell.value ? (
              <div>
                <p className="m-0 text-start">
                  {moment(cell.value).format("YYYY-MM-DD")}
                </p>
                <p className="m-0 text-start text-muted">
                  {moment(cell.value).format("hh:mm")}
                </p>
              </div>
            ) : (
              "-"
            )}
          </div>
        ),
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    setLoading(true);
    const [err, result] = await catchAsync(
      cmsHubStatistics.getArticles(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }

    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : moment().subtract(1, "year").format("YYYY-MM-DD"),
      end_date: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      publish_start_date: publish_start_date
        ? moment(publish_start_date).format("YYYY-MM-DD")
        : "",
      publish_end_date: publish_end_date
        ? moment(publish_end_date).format("YYYY-MM-DD")
        : "",
      search: keyword,
      page: 1,
    });
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().toDate(), moment().toDate()]);
    setDatePublishSearch([
      moment().toDate(),
      moment().toDate(),
    ]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePickerPublish = (values: any[] = []) => {
    setDatePublishSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center">
            <Card
              className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
              style={{ width: "200px" }}
            >
              <CardBody>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                      {t("Total")}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                      <span className="counter-value text-primary">
                        {loading ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          <CountUp
                            start={0}
                            end={pagination?.total || 0}
                            duration={1}
                          />
                        )}
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="w-100">
              <Row className="g-4 align-items-center mb-2 mt-2">
                <Col
                  sm={12}
                  md={3}
                  lg={3}
                  className="date-picker-wrapper-custom mt-3 mt-md-2"
                >
                  <LabelWrapper
                    label={t("Date")}
                    isShow={!!startDate || !!endDate}
                  >
                    <DatePickerCustom
                      placeholder={`${t("Start Date")} - ${t("End Date")}`}
                      startDate={startDate || null}
                      endDate={endDate || null}
                      onChangePicker={handleChangePicker}
                    />
                  </LabelWrapper>
                </Col>
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={6} lg={6} className="mt-2 mt-md-2">
                  <Input
                    type="text"
                    className="form-control search"
                    placeholder={`${t("cms_media.input_search")}...`}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchCategory();
                      }
                    }}
                  />
                </Col>
                <Col
                  sm={12}
                  md={3}
                  lg={3}
                  className="date-picker-wrapper-custom mt-3 mt-md-2"
                >
                  <LabelWrapper
                    label={t("Date")}
                    isShow={!!publish_start_date || !!publish_end_date}
                  >
                    <DatePickerCustom
                      placeholder={`${t("Start Published at")} - ${t(
                        "End Published at"
                      )}`}
                      startDate={publish_start_date || null}
                      endDate={publish_end_date || null}
                      onChangePicker={handleChangePickerPublish}
                    />
                  </LabelWrapper>
                </Col>
                <Col
                  sm={12}
                  md={3}
                  lg={3}
                  className="hstack gap-1 justify-content-sm-center justify-content-md-start mt-4 mt-md-2"
                >
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary me-1"
                      onClick={handleSearchCategory}
                      disabled={loading}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light align-middle"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Article;
