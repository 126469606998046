import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { Row, Table } from "reactstrap";
import { DefaultColumnFilter } from "./filters";

import { formatNumberWithCommas } from "helpers/format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import TooltipCustom from "./TooltipCustom";
import { useDraggable } from "react-use-draggable-scroll";

interface TableContainerProps {
  className?: any;
  columns?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string; order_by: string };
  handleChangeSorting?: (sortBy: {
    sort_by: string | undefined | null;
    order_by: string | undefined | null;
  }) => void;
  manualSorting?: boolean;
  isLoading?: boolean;
  isShowPagination?: boolean;
  isShowLoadingBottom?: boolean;
  isShowColumn?: boolean;
  classBackgroundWhenActiveSort?: string;
  isScrollToTop?: boolean;
  onClickRow?: (event: any, row: any) => void;
  scrollbarOs?: boolean;
  messageEmpty?: string;
  heightEmpty?: string;
}

const TableContainer = ({
  columns = [],
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = "",
  isScrollToTop = true,
  onClickRow,
  scrollbarOs = true,
  messageEmpty = "",
  heightEmpty = "70px",
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: +customPageIndex || 0,
        pageSize: +customPageSize || 0,
        selectedRowIds: 0,
      },
      pageCount: +customPageCount || 0,
      manualPagination: true,
      // manualSorting: true,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const { t } = useTranslation();

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);

  const thRefs = useRef<any>([]); // Fixed Header Table
  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementHeaderFixedRef = useRef<any>(null); // Fixed Header Table
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any =
    warperTableRef?.current?.getBoundingClientRect() || {};

  const [isShowHeaderTableFixed, setIsShowHeaderTableFixed] =
    useState<boolean>(false); // Fixed Header Table

  const { events } = useDraggable(warperTableRef);

  const generateSortingIndicator = (column: any) => {
    return column?.sortable ? (
      <span
        style={{
          display: "inline-flex",
          justifyContent: "center",
          width: "12px",
          marginLeft: "2px",
          verticalAlign: "middle",
        }}
      >
        {column.id === sorting?.sort_by && sorting?.order_by === "ASC" && (
          <i className={`ri-arrow-up-line fs-13 text-primary`} />
        )}
        {column.id === sorting?.sort_by && sorting?.order_by === "DESC" && (
          <i className={`ri-arrow-down-line fs-13 text-primary`} />
        )}
      </span>
    ) : (
      ""
    );
  };
  const generateDescription = (column: any) => {
    return column?.description ? (
      <TooltipCustom
        title={column?.description || ""}
        className="d-inline-block vertical-align-middle ms-1"
        style={{ transform: "translateY(2px)" }}
      >
        <i className="ri-question-line align-bottom text-secondary"></i>
      </TooltipCustom>
    ) : (
      <></>
    );
  };

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft);
    if (warperTableRef.current && elementFixedRef.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
        elementHeaderFixedRef.current.scrollLeft = e?.target?.scrollLeft; // Fixed Header Table
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if (scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }

      // Begin::Fixed Header Table
      const rect = warperTableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top;
      if (distanceFromTop <= 70) {
        elementHeaderFixedRef.current.scrollLeft =
          elementFixedRef?.current?.scrollLeft; // Fixed Header Table
        setIsShowHeaderTableFixed((prev) => true);
      } else {
        setIsShowHeaderTableFixed((prev) => false);
      }
      // End::Fixed Header Table
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <div
        className={`table-custom-pagination ${divClass}`}
        {...events}
        ref={warperTableRef}
        onScroll={handleScroll}
      >
        <Table
          hover
          {...getTableProps()}
          className={`${tableClass} ${
            Number(scrollLeft || 0) > 0 && "is-scroll"
          }`}
          style={{ minHeight: "70px" }}
        >
          <thead className={theadClass} ref={tableRef}>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={column.id}
                    ref={(el) => (thRefs.current[i] = el)} // Fixed Header Table
                    className={`${thClass || ""} ${column?.thClass || ""} ${
                      column?.sortable &&
                      column.id === sorting?.sort_by &&
                      classBackgroundWhenActiveSort
                    }`}
                    {...(column?.thWidth ? { width: column?.thWidth } : {})}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={(e) => {
                      if (!column?.sortable) {
                        return;
                      }

                      if (!sorting?.sort_by || !sorting?.order_by) {
                        const sortBy = {
                          sort_by: column?.id,
                          order_by: "DESC",
                        };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      } else if (sorting?.sort_by && sorting?.order_by) {
                        const sortBy = {
                          sort_by: column?.id,
                          order_by:
                            sorting?.order_by === "DESC" &&
                            sorting?.sort_by === column?.id
                              ? "ASC"
                              : "DESC",
                        };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      }
                    }}
                  >
                    {column?.thComponent ? (
                      <>{column?.thComponent(column)}</>
                    ) : (
                      column.render("Header")
                    )}
                    {generateDescription(column)}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
            {headerGroups?.length && (
              <tr>
                <th
                  colSpan={headerGroups[0]?.headers?.length}
                  className="py-0 px-0"
                  style={{ borderBottomColor: "transparent" }}
                >
                  <div
                    className={`infinite-loading-outer ${
                      isLoading && "is-show-loading"
                    }`}
                  >
                    <div className="infinite-loading-inner"></div>
                  </div>
                </th>
              </tr>
            )}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.length ? (
              page?.map((row: any) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            onClick={(event) => {
                              onClickRow &&
                                onClickRow(
                                  event,
                                  cell.render("Cell")?.props?.row
                                );
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })
            ) : (
              <tr style={{ height: heightEmpty }}>
                <td
                  colSpan={headerGroups[0]?.headers?.length}
                  className="text-center"
                >
                  {messageEmpty ? messageEmpty : ""}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Begin::Fixed Header Table */}
      <div
        className={`table-card overflow-x-auto m-0 hidden-scrollbar-os ${
          isShowHeaderTableFixed ? "" : "d-none"
        }`}
        style={{
          position: "fixed",
          top: 70,
          zIndex: 9,
          left: elementWarperTable?.left,
          width: elementWarperTable?.width,
          right: 0,
        }}
        ref={elementHeaderFixedRef}
      >
        <Table
          hover
          {...getTableProps()}
          className={`${tableClass} ${
            Number(scrollLeft || 0) > 0 && "is-scroll"
          } mb-0`}
          style={{ height: "auto", tableLayout: "fixed" }}
        >
          <thead className={theadClass} style={{ width: elementTable?.width }}>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={column.id}
                    className={`${thClass || ""} ${column?.thClass || ""} ${
                      column?.sortable &&
                      column.id === sorting?.sort_by &&
                      classBackgroundWhenActiveSort
                    }`}
                    {...(column?.thWidth
                      ? { width: thRefs.current[i]?.offsetWidth }
                      : { width: thRefs.current[i]?.offsetWidth })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={(e) => {
                      if (!column?.sortable) {
                        return;
                      }

                      if (!sorting?.sort_by || !sorting?.order_by) {
                        const sortBy = {
                          sort_by: column?.id,
                          order_by: "DESC",
                        };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      } else if (sorting?.sort_by && sorting?.order_by) {
                        const sortBy = {
                          sort_by: column?.id,
                          order_by:
                            sorting?.order_by === "DESC" &&
                            sorting?.sort_by === column?.id
                              ? "ASC"
                              : "DESC",
                        };
                        handleChangeSorting && handleChangeSorting(sortBy);
                      }
                    }}
                  >
                    {column?.thComponent ? (
                      <>{column?.thComponent()}</>
                    ) : (
                      column.render("Header")
                    )}
                    {generateDescription(column)}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
            {headerGroups?.length && (
              <tr>
                <th
                  colSpan={headerGroups[0]?.headers?.length}
                  className="py-0 px-0"
                  style={{ borderBottomColor: "transparent" }}
                >
                  <div
                    className={`infinite-loading-outer ${
                      isLoading && "is-show-loading"
                    }`}
                  >
                    <div className="infinite-loading-inner"></div>
                  </div>
                </th>
              </tr>
            )}
          </thead>
        </Table>
      </div>
      {/* End::Fixed Header Table */}

      {scrollbarOs ? (
        <div
          className="overflow-x-auto color-scrollbar-os"
          style={{
            position: "fixed",
            zIndex: 999,
            left: elementWarperTable?.left,
            bottom: 0,
            width: elementWarperTable?.width,
            right: 0,
            opacity: isScrollBottom ? 0 : 1,
          }}
          ref={elementFixedRef}
          onScroll={handleScroll}
        >
          <div style={{ width: elementTable?.width, height: "1px" }}></div>
        </div>
      ) : null}

      {isShowLoadingBottom && isLoading && (
        <div className=" d-flex text-center align-items-center justify-content-center pt-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100px"
            height="60px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <g transform="translate(20 50)">
              <circle cx="0" cy="0" r="10" fill="#3498db">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.375s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
            <g transform="translate(40 50)">
              <circle cx="0" cy="0" r="10" fill="#53a6de">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.25s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
            <g transform="translate(60 50)">
              <circle cx="0" cy="0" r="10" fill="#5fb5ef">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.125s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
            <g transform="translate(80 50)">
              <circle cx="0" cy="0" r="10" fill="#5ca6d8">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="0s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </g>
          </svg>
        </div>
      )}
      {isShowPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              {t("Total")}:{" "}
              <span className="fw-semibold ms-1">
                {formatNumberWithCommas(totalRecords)}
              </span>{" "}
              {t("Results")}
            </div>
          </div>
          <div className="col-sm-auto">
            <ReactPaginate
              nextLabel={`${t("next")} >`}
              onPageChange={(page) => {
                if (handleChangePage) {
                  isScrollToTop && handleScrollToTop();
                  handleChangePage(page.selected);
                }
              }}
              forcePage={pageIndex}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={customPageCount}
              previousLabel={`< ${t("previous")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-separated"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableContainer);
