import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import { catchAsync, formatWithKoreanAmPm } from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresCmsPopup } from "api/types/_cmsHub";
import LabelWrapper from "components/Common/LabelWrapper";
import DropdownOption from "components/Common/DropdownOption";
import ModalPopup from "./ModalPopup";

const PopupTab = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
    created_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    created_to: moment().format("YYYY-MM-DD"),
    is_active: "1",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.created_from || "", "Y-MM-DD").toDate(),
    moment(queryParams?.created_to || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresCmsPopup>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [modal, setModal] = useState({
    isOpen: false,
    id: 0,
    company_id: 0,
  });

  const POPUP_STATUS = {
    EXPRIED: 0,
    DISPLAY: 1,
    PERIOD_DISPLAY: 2,
  };

  const OPTION_SORT = [
    { value: "1", label: "노출" },
    { value: "2", label: "종료" },
  ];

  const statusPopup = [
    {
      label: t("cms_media.exposure"),
      key: POPUP_STATUS.DISPLAY,
      bg: "#E1EBFF",
      color: "#1C6BFF",
    },
    {
      label: t("cms_media.period_exposure"),
      key: POPUP_STATUS.PERIOD_DISPLAY,
      bg: "#FFEEC2",
      color: "#FF8A1E",
    },
    {
      label: t("cms_media.end"),
      key: POPUP_STATUS.EXPRIED,
      bg: "#EBEEF1",
      color: "#5F646F",
    },
  ];

  // Column
  const columns = useMemo(() => {
    const renderRangeDate = (item: IresCmsPopup) => {
      return (
        <>
          {item.status === POPUP_STATUS.PERIOD_DISPLAY &&
            item.valid_to &&
            item.valid_from && (
              <div className="text-[12px] font-[400] text-[#5F646F]">
                <div>{formatWithKoreanAmPm(item.valid_from)}~</div>
                <div>{formatWithKoreanAmPm(item.valid_to)}</div>
              </div>
            )}
          {item.status === POPUP_STATUS.DISPLAY && (
            <div className="text-[12px] font-[400] text-[#5F646F]">
              {formatWithKoreanAmPm(item.created_at)}~
            </div>
          )}
          {item.status === POPUP_STATUS.EXPRIED && (
            <>
              {item.valid_to && item.valid_from ? (
                <div className="text-[12px] font-[400] text-[#5F646F]">
                  <div>{formatWithKoreanAmPm(item.valid_from)}~</div>
                  <div>{formatWithKoreanAmPm(item.valid_to)}</div>
                </div>
              ) : (
                <div className="text-[12px] font-[400] text-[#5F646F]">
                  {formatWithKoreanAmPm(item.created_at)}~
                </div>
              )}
            </>
          )}
        </>
      );
    };
    return [
      {
        Header: t("cms_media.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {" "}
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              row.index}
          </div>
        ),
      },
      {
        Header: t("cms_media.media_name"),
        accessor: "company_name",
        filterable: false,
        sortable: false,
      },
      {
        Header: t("cms_media.title"),
        accessor: "title",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => (
          <div
            style={{
              textUnderlineOffset: "2px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setModal({
              isOpen: true,
              id: row?.original?.id,
              company_id: row?.original?.company_id
            })}
          >
            {row.original?.title || ""}
          </div>
        ),
      },
      {
        Header: t("cms_media.attachment"),
        accessor: "attachment",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => renderAttachment(row.original),
      },
      {
        Header: t("cms_media.link_address"),
        accessor: "link_url",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => (
          <div
            style={{
              maxWidth: "400px",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.original?.link_url?.length ? (
              <a
                href={row.original?.link_url}
                target="_blank"
                style={{ display: "contents" }}
              >
                {row.original?.link_url}
              </a>
            ) : (
              <span>-</span>
            )}
          </div>
        ),
      },
      {
        Header: t("cms_media.situation"),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {statusPopup.find((v) => v.key === row.original?.status)?.label}
          </div>
        ),
      },
      {
        Header: t("cms_media.period"),
        accessor: "period",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {renderRangeDate(row?.original as IresCmsPopup)}
          </div>
        ),
      },
    ];
  }, [t, i18n?.language, pagination]);

  const renderAttachment = (original: IresCmsPopup) => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          background: original?.attachments?.length
            ? `url(${original.attachments[0]?.path})`
            : "#D9D9D9",
          width: "40px",
          height: "40px",
          borderRadius: "5px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "white",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              original?.attachments?.length > 1 ? "rgba(0, 0, 0, 0.5)" : "none",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "14px",
          }}
        >
          {original?.attachments?.length > 1
            ? `+${original?.attachments?.length - 1}`
            : ""}
        </div>
      </div>
    );
  };

  const paging = async () => {
    queryParams.created_from = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    queryParams.created_to = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    queryParams.is_active = queryParams.is_active == "1" ? "true" : "false";
    const [err, result] = await catchAsync(
      cmsHubMediaApi.cmshubPopups(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      created_from: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      created_to:endDate ? moment(endDate).format("YYYY-MM-DD") : null
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  }, []);

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
            <div className="w-100">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div
                    className="d-flex align-items-center date-picker-wrapper-custom"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">{t('cms_media.period')}</div>
                    <div style={{ width: "300px" }}>
                      <DatePickerCustom
                        placeholder={`${t("A From Range")} - ${t(
                          "A To Range"
                        )}`}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        disabled={false}
                        isClearable={true}
                        onChangePicker={handleChangePicker}
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <DropdownOption
                    name="category-type"
                    dataList={OPTION_SORT}
                    placeholder={`${t("Category Type")}...`}
                    className="search-filter-category-type"
                    classNamePrefix="name-prefix"
                    initialValue={OPTION_SORT.find(
                      (x) => x.value == queryParams?.is_active
                    )}
                    onChangeSelect={(e) => {
                      setQueryParams({
                        ...queryParams,
                        is_active: String(e?.value),
                        page: 1,
                      });
                    }}
                    isHasOptionAll={false}
                  />
                </Col>
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">검색</div>
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder={`${t("cms_media.input_search_popup")}...`}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchCategory();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                >
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSearchCategory}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
      <ModalPopup
        id={modal.id}
        company_id={modal.company_id}
        isOpen={modal.isOpen}
        onClose={() =>
          setModal({
            isOpen: false,
            id: 0,
            company_id: 0
          })
        }
        refresh={() => paging()}
      />
    </React.Fragment>
  );
};

export default PopupTab;
