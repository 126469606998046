import cmsHubMembershipApi from "api/membershipApi";
import { SCRIPT_TYPE_LABEL, SERVICE_TYPE } from "helpers/constans";
import {
  catchAsync,
  formatDate,
  formatNumber,
  formatWithKoreanAmPm,
} from "helpers/general";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
interface Props {
  isOpen: boolean;
  id: number;
  onClose: () => void;
}

const MediaInfomation = (props: Props) => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState({} as any);
  const getHistory = async () => {
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.getDetailCompany(props.id)
    );
    if (err) {
      console.log(err);
      return;
    }
    setDetail(result?.data);
  };
  useEffect(() => {
    if (props.id && props.isOpen) {
      getHistory();
    } else {
      setDetail({});
    }
  }, [props.id, props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered={true}
      scrollable={false}
      style={{ width: "744px", maxWidth: "744px" }}
    >
      <div className="d-flex pt-3 pb-2 px-3 items-center">
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("membership.media_information")}
        </div>
      </div>
      <ModalBody>
        <div className="d-flex">
          <div className="d-flex flex-column gap-4 w-50">
            <div className="d-flex gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.media_name")}
              </div>
              <div>{detail?.name || "-"}</div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.media_classification")}
              </div>
              <div>{detail?.media_category || "-"}</div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.domain_URL")}
              </div>
              <div
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "3px",
                }}
              >
                <a
                  href={detail?.temp_domain}
                  target="_blank"
                  className="ellipsis-1"
                  style={{ maxWidth: "230px" }}
                >
                  {detail?.temp_domain || "-"}
                </a>
              </div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.service_subscription_date")}
              </div>
              <div>
                {detail?.services?.map((item: any) => (
                  <div className="d-flex gap-2 align-items-center">
                    {
                      SERVICE_TYPE.CMS_HUB === item.service_type ? <div style={{backgroundColor:'#2860DB',color:'#fff',padding:'2px 10px',borderRadius:'5px'}}>{item?.service_name}</div> : <></>
                    }
                    {
                      SERVICE_TYPE.PR_HUB === item.service_type ? <div style={{backgroundColor:'#CC1A3A',color:'#fff',padding:'2px 10px',borderRadius:'5px'}}>{item?.service_name}</div> : <></>
                    }
                    {
                      SERVICE_TYPE.NEWS_HUB === item.service_type ? <div style={{backgroundColor:'#5F646F',color:'#fff',padding:'2px 10px',borderRadius:'5px'}}>{item?.service_name}</div> : <></>
                    }
                    <div>{moment(item?.created_at).format("YYYY.MM.DD")}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.CMS_information")}
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex  gap-2">
                  <div className="fw-bold" style={{ minWidth: "85px" }}>
                    {t("membership.membership")}
                  </div>
                  <div>{detail?.cms_information?.membership}</div>
                </div>
                <div className="d-flex  gap-2">
                  <div className="fw-bold" style={{ minWidth: "85px" }}>
                    {t("membership.payment_date")}
                  </div>
                  <div>
                    {formatDate(
                      detail?.cms_information?.payment_date
                    )}
                  </div>
                </div>
                <div className="d-flex  gap-2">
                  <div className="fw-bold" style={{ minWidth: "85px" }}>
                    {t("membership.next_payment_date")}
                  </div>
                  <div>
                    {formatDate(
                      detail?.cms_information?.next_payment_date
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="fw-bold" style={{ minWidth: "85px" }}>
                    {t("membership.point")}
                  </div>
                  <div>{formatNumber(detail?.cms_information?.point)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 w-50">
            <div className="d-flex">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.manager")}
              </div>
              <div>{detail?.admin?.name}</div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.email")}
              </div>
              <div>{detail?.admin?.email}</div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.phone_number")}
              </div>
              <div>{detail?.admin?.phone_number}</div>
            </div>
            <div className="d-flex  gap-2">
              <div className="fw-bold" style={{ minWidth: "85px" }}>
                {t("membership.script_status")}
              </div>
              <div className="d-flex flex-column gap-1">
                <div>{SCRIPT_TYPE_LABEL[detail?.srcipt_status?.script_type]}</div>
                {
                  detail?.srcipt_status?.updated_at ? 
                  <div style={{ color: "#556EE6" }}>
                  {formatWithKoreanAmPm(detail?.srcipt_status?.updated_at)}_{detail?.srcipt_status?.updated_by}
                </div> : <></> 
                }
              </div>
            </div>
          </div>
        </div>
        <div className="hstack mt-5 justify-content-center px-5">
          <button
            className="btn btn-soft-secondary fs-14"
            type="button"
            color="light"
            style={{ width: "185px" }}
            onClick={() => props?.onClose()}
          >
            {t("cms_media.cancellation")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MediaInfomation;
