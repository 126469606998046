import { useDebounce } from "components/Hooks/useDebounce";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import test from "../../../assets/images/cmshub/table-popup.svg";

interface Props {
  positionDefault?: { x: number; y: number };
  controlBoxRef: any;
  onChangePosition: (position: { x: number; y: number }) => void;
}
const ControlBox = ({
  positionDefault,
  controlBoxRef,
  onChangePosition,
}: Props) => {
  const dispatch = useDispatch();
  const [controlBoxPosition, setControlBoxPosition] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onControlBoxDrag = (_: any, uiData: any) => {
    const controlBoxParentRect =
      controlBoxRef.current.parentNode.getBoundingClientRect();
    const controlBoxRatioX =
      uiData.x / (controlBoxParentRect.width - uiData.node.offsetWidth);
    const controlBoxRatioY =
      uiData.y / (controlBoxParentRect.height - uiData.node.offsetHeight);

    setControlBoxPosition({ x: uiData.x, y: uiData.y });
    const position = {
      x: controlBoxRatioX,
      y: controlBoxRatioY,
    };
    setPosition(position);
  };

  const debounced = useDebounce(position, 200);
  useEffect(() => {
    if (debounced) {
      onChangePosition(debounced);
    }
  }, [debounced]);

  useEffect(() => {
    if (positionDefault) {
      setControlBoxPosition(positionDefault);
    }
  }, [positionDefault]);

  return (
    <div
      style={{
        backgroundImage: `url(${test})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        position: "relative",
        overflow: "auto",
        padding: "0",
        width: "218px",
        height: "130px",
      }}
    >
      <Draggable
        bounds="parent"
        onDrag={onControlBoxDrag}
        position={controlBoxPosition}
      >
        <div
          ref={controlBoxRef}
          style={{
            width: "36px",
            height: "36px",
            backgroundColor: "#1C6BFF",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        ></div>
      </Draggable>
    </div>
  );
};

export default ControlBox;
