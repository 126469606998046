import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//Naver
import NaverReducer from "./naver/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

//Car
import CarReducer from "./car/reducer";

//Movie
import MovieReducer from "./movie/reducer";

//Campaign
import CampaignReducer from "./campaign/reducer";

//News
import NewsReducer from "./news/reducer";

//Template
import TemplateReducer from "./template/reducer";

//Topic
import TopicReducer from "./topic/reducer";

//Media
import MediaReducer from "./media/reducer";

//Settlement
import SettlementReducer from "./settlement/reducer";

//Category
import CategoryReducer from "./category/reducer";

// ExchangeRate
import ExchangeRateReducer from "./currency/reducer";

//TrendIndex
import TrendIndexReducer from "./trendIndex/reducer";

//ChatGPT
import ChatGPTReducer from "./chatgpt/reducer";

// Statistic
import StatisticReducer from "./statistic/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";

// Stock
import StockReducer from "./stock/reducer";

// Sport
import SportReducer from "./sport/reducer";

// Lotto
import LottoReducer from "./lotto/reducer";

// Keyword
import KeywordReducer from "./keyword/reducer";

// Lab
import LabReducer from "./lab/reducer";

// Global
import GlobalReducer from "./global/reducer";

// Configuration
import ConfigurationReducer from "./configuration/reducer";

// Logging
import LoggingReducer from "./logging/reducer";

// Coin
import CoinReducer from "./coin/reducer";

// Company
import CompanyReducer from "./company/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Naver: NaverReducer,
  Dashboard: DashboardReducer,
  Car: CarReducer,
  Movie: MovieReducer,
  Campaign: CampaignReducer,
  News: NewsReducer,
  Template: TemplateReducer,
  Topic: TopicReducer,
  Media: MediaReducer,
  Settlement: SettlementReducer,
  Category: CategoryReducer,
  ExchangeRate: ExchangeRateReducer,
  TrendIndex: TrendIndexReducer,
  ChatGPT: ChatGPTReducer,
  Statistic: StatisticReducer,
  User: UserReducer,
  Role: RoleReducer,
  Stock: StockReducer,
  Sport: SportReducer,
  Lotto: LottoReducer,
  Keyword: KeywordReducer,
  Lab: LabReducer,
  Global: GlobalReducer,
  Configuration: ConfigurationReducer,
  Logging: LoggingReducer,
  Coin: CoinReducer,
  Company: CompanyReducer,
});

export default rootReducer;
