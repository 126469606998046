import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";

import { QueryParams } from "components/Hooks/useQueryParams";
import { IInquiryCustomer, IInquiryCustomerDetail, IInquiryHomePage, ISummary, ResponseInquiryList } from "./types/_inquiry";
const api = new APIClient();

const path = "/inquiries";

const inquiryApi = {
  getListHomePage(dataForm: QueryParams): Promise<AxiosResponse<ResponseInquiryList<IInquiryHomePage[]>, any>> {
    const url = `${path}/homepage`;
    return api.get(url, dataForm);
  },
  getSummariesHomePage(params: any): Promise<AxiosResponse<Array<ISummary>, any>> {
    const url = `${path}/homepage/summary`;
    return api.get(url, params);
  },
  updateReplyHomePage({ id, body }: { id: number; body: any }): Promise<AxiosResponse<any, any>> {
    const url = `${path}/homepage/${id}/reply`;
    return api.post(url, body);
  },
  getTotalWaiting(params: any): Promise<AxiosResponse<number, any>> {
    const url = `${path}/homepage/total-waiting`;
    return api.get(url, params);
  },
  getSummaries(params: any): Promise<AxiosResponse<Array<ISummary>, any>> {
    const url = `${path}/customer/summary`;
    return api.get(url, params);
  },
  getList(dataForm: QueryParams): Promise<AxiosResponse<ResponseInquiryList<IInquiryCustomer[]>, any>> {
    const url = `${path}/customer`;
    return api.get(url, dataForm);
  },
  getDetail(id: string, dataForm: QueryParams): Promise<AxiosResponse<IInquiryCustomerDetail, any>> {
    const url = `${path}/customer/${id}`;
    return api.get(url, dataForm);
  },
  updateReply({ id, body }: { id: number; body: any }): Promise<AxiosResponse<any, any>> {
    const url = `${path}/customer/${id}/reply`;
    return api.post(url, body, {
      headers: {
        "access-control-allow-headers": "*",
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateStatusWaiting(id: string): Promise<AxiosResponse<any, any>> {
    const url = `${path}/customer/${id}/waiting`;
    return api.patch(url, {});
  },
  updateStatusInProgress(id: string): Promise<AxiosResponse<any, any>> {
    const url = `${path}/customer/${id}/processing`;
    return api.patch(url, {});
  },
};
export default inquiryApi;
