import { createAsyncThunk } from "@reduxjs/toolkit";
import companyApi from "api/companyApi";

export const getAllCompany = createAsyncThunk("company", async () => {
  try {
    const response = await companyApi.allCompanies();
    return response;
  } catch (error) {
    return error;
  }
});
