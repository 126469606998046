import koTranslation from "./locales/ko.json";
import enTranslation from "./locales/en.json";

export const mediaResources = {
  ko: {
    translation: koTranslation,
  },
  en: {
    translation: enTranslation,
  },
};
