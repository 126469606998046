import React, { useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
//
import MediaTab from "./Media/MediaTab";
import HomePage from "./Homepage/HomePageTab";
import PopupTab from "./Popup/PopupTab";
import useQueryParams from "components/Hooks/useQueryParams";
import ReporterTab from "./Reporter";

const CmsHubMedia = () => {
  const { t } = useTranslation();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const { clearQueryParams } = useQueryParams();
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const tabs = [
    {
      id: "1",
      label: t("cms_media.media_management"),
      content: <MediaTab />,
      route: "media",
    },
    {
      id: "2",
      label: t("cms_media.managing_homepage"),
      content: <HomePage />,
      route: "homepage",
    },
    {
      id: "3",
      label: t("cms_media.popup_homepage"),
      content: <PopupTab />,
      route: "popup",
    },
    {
      id: "4",
      label: t("cms_media.reporter_homepage"),
      content: <ReporterTab />,
      route: "reporter",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb
            title={
              tabs.find((tab) => location?.pathname?.includes(tab.route))?.label
            }
          /> */}
          <Nav tabs className="nav nav-tabs nav-tabs-custom mb-3">
            {tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === tab.id,
                  })}
                  onClick={() => {
                    toggleCustom(tab.id);
                    clearQueryParams();
                  }}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={customActiveTab} className="text-muted">
            {tabs.map((tab) => (
              <TabPane tabId={tab.id} key={tab.id} id={`tab-${tab.id}`}>
                {customActiveTab === tab.id && tab.content}
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CmsHubMedia;
