import { useTranslation } from "react-i18next";
interface Props {
  id: number;
  redirect?:string;
}
const ButtonMediaAccess = ({ id, redirect }: Props) => {
  const { t } = useTranslation();
  const onClick = () => {
    const url = `${process.env.REACT_APP_CONSOLE_DOMAIN}/auth/verify-super-admin?access_token=${localStorage.getItem('access_token')}&company_id=${id}&redirect=${redirect}`;
    window.open(url, "_blank");
  };

  return (
    <button
      className="btn btn-primary fs-14 text-nowrap"
      disabled={false}
      type="button"
      onClick={onClick}
    >
      {t("cms_media.media_manager_access")}
    </button>
  );
};

export default ButtonMediaAccess;
