/* eslint-disable eqeqeq */
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Label, Modal, ModalBody, Spinner } from "reactstrap";
import photoSvg from "../../assets/images/inquiry/photo.svg";
import { INQUIRY_STATUS, TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS } from "helpers/constans";
import iconFile from "../../assets/images/icon-file.svg";

const AcceptFileType = ["image/jpg", "application/pdf", "image/png", "image/jpeg"];

function ModalDetail({
  selectedRowID,
  loadingDetail,
  onCloseClick,
  inquiryDetail,
  handleGetMedia,
  setIndex,
  setIndexReply,
  choseFile,
  textReply,
  setTextReply,
  isEdit,
  handleRemoveImage,
  handleUploadFile,
  isDeductPoints,
  setIsDeductPoints,
  deductPoints,
  setDeductPoints,
  changeStatusToInProgress,
  showModal,
  changeStatusToWaiting,
  submitReply,
  loadingReply,
  setIsEdit,
  loadingMedia,
}: any) {
  const { t } = useTranslation();

  const renderStatus = (statusValue: string | number) => {
    const statusOptions = new Map(INQUIRY_STATUS.map((i) => [i.value, i.label]));
    return t(statusOptions.get(statusValue?.toString()) || "-");
  };

  const renderService = (service_type: number) => {
    let tagName: string = "";
    const servicesKeyType = new Map(
      TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [i.value, i.label])
    );
    tagName += `${`<div class='tag-service bg-${servicesKeyType.get(
      service_type.toString()
    )}'  >${servicesKeyType.get(service_type.toString()) === "Presscon" ? "Prc" : servicesKeyType.get(service_type.toString())}  </div>`}`;
    return (
      <div
        className='mx-auto'
        style={{
          width: "fit-content",
        }}
        dangerouslySetInnerHTML={{ __html: tagName }}
      />
    );
  };

  return (
    <Modal
      isOpen={!!selectedRowID}
      centered={true}
      scrollable={true}
      style={{ maxWidth: "1000px", width: "100%" }}
    >
      <ModalBody
        className=''
        style={{
          paddingTop: "0",
        }}
      >
        <div>
          <div
            className='d-relative'
            style={{
              position: "sticky",
              top: "0",
              background: "white",
              paddingTop: "20px",
            }}
          >
            <h5 className='text-center w-100 mb-4'>{t("inquiry.customer_inquiry_detail")}</h5>
            <div
              className='position-absolute cursor-pointer'
              style={{
                top: "12px",
                right: "0px",
              }}
            >
              <button
                onClick={onCloseClick}
                type='button'
                className='btn-close'
                aria-label='Close'
              ></button>
            </div>
          </div>
          <div className='d-flex gap-4 align-items-center py-1'>
            <Label className='form-label mb-0'>{t("inquiry.Service")}</Label>
            <div
              className='mb-0'
              style={{
                width: "fit-content",
              }}
            >
              {renderService(inquiryDetail?.service_type || "")}
            </div>
          </div>
          <div className='d-flex gap-5 py-1'>
            <div className='d-flex gap-4 align-items-center'>
              <Label className='form-label mb-0'>{t("inquiry.Inquiry_type")}</Label>
              <p className='mb-0'>{inquiryDetail?.inquiry_category?.name}</p>
            </div>
            <div className='d-flex gap-1 align-items-center'>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.Author")}</Label>
                <p
                  className='mb-0'
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!loadingMedia) {
                      handleGetMedia(inquiryDetail?.company?.id);
                    }
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {inquiryDetail?.created_by?.name}
                </p>
              </div>
              <Spinner
                size={"sm"}
                style={{
                  visibility: loadingMedia ? "visible" : "hidden",
                }}
              />
            </div>
            <div className='d-flex gap-4 align-items-center'>
              <Label className='form-label mb-0'>{t("inquiry.Created_At")}</Label>
              <p className='mb-0'>
                {moment(inquiryDetail?.created_at).isValid()
                  ? moment(inquiryDetail?.created_at).format("YYYY.MM.DD HH:mm:ss")
                  : "-"}
              </p>
            </div>
            <div className='d-flex gap-4 align-items-center'>
              <Label className='form-label mb-0'>{t("inquiry.Status")}</Label>
              <p className='mb-0'>{renderStatus(inquiryDetail?.status)}</p>
            </div>
          </div>
          <div className='mb-2'>
            <div className='mt-2 py-1'>
              <Label className='form-label'>{t("inquiry.detail")}</Label>
              {inquiryDetail?.inquiry_category?.code === "domain_management" ? (
                <>
                  <div>
                    <p>
                      {t("inquiry.FORM.DOMAIN.LABEL")}: {inquiryDetail?.details?.domain_purchaser}
                    </p>
                    <p>
                      {t("inquiry.FORM.ID.LABEL")}: {inquiryDetail?.details?.identity}
                    </p>
                    <p>
                      {t("inquiry.FORM.PASSWORD.LABEL")}: {inquiryDetail?.details?.password}
                    </p>
                    <p>
                      {t("inquiry.FORM.CONTACT_NUMBER.LABEL")}: {inquiryDetail?.details?.manager}
                    </p>
                    <p>
                      {t("inquiry.FORM.PHONE.LABEL")}: {inquiryDetail?.details?.phone_number}
                    </p>
                    <p
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {t("inquiry.FORM.DETAIL.LABEL")}: {inquiryDetail?.description}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p
                    className='mb-0'
                    style={{
                      wordBreak: "break-all",
                      maxHeight: "200px",
                      overflow: "auto",
                    }}
                  >
                    {inquiryDetail?.description}
                  </p>
                </>
              )}
            </div>

            {inquiryDetail?.inquiry_files?.length > 0 && (
              <div className='d-flex gap-2 my-4'>
                {inquiryDetail?.inquiry_files?.map(
                  (file: { id: number; path: string }, index: number) => (
                    <div
                      key={file?.id}
                      className='d-flex flex-col align-items-center justify--content-center gap-1 py-2'
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                    >
                      <img
                        src={
                          file.path?.split(".")?.pop()?.toLowerCase() === "pdf"
                            ? iconFile
                            : file.path
                        }
                        alt='inquiry'
                        style={{
                          width: "60px",
                          height: "60px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIndex(index)}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div
            className=''
            style={{
              visibility:
                inquiryDetail?.status == "1" || inquiryDetail?.status == "2" ? "visible" : "hidden",
            }}
          >
            <Label className='form-label'>{t("inquiry.customer_answer")}</Label>
            <textarea
              className='form-control'
              id='reply'
              rows={5}
              placeholder={t("inquiry.PlaceholderMemo")}
              value={textReply}
              onChange={(e: any) => setTextReply(e.target.value)}
              disabled={!isEdit && inquiryDetail?.status == "2"}
            />
            {inquiryDetail?.status == "2" && (
              <div className='d-flex gap-5 py-1'>
                <div className='d-flex gap-4 align-items-center'>
                  <Label className='form-label mb-0'>{t("inquiry.CS_Manager")}</Label>
                  <p className='mb-0'>{inquiryDetail?.managed_by?.name}</p>
                </div>
                <div className='d-flex gap-4 align-items-center'>
                  <Label className='form-label mb-0'>{t("inquiry.Deduction_points")}</Label>
                  <p className='mb-0'>{inquiryDetail?.deduction_point}</p>
                </div>
              </div>
            )}
            <div className='d-flex gap-2 my-4'>
              {choseFile.length > 0 &&
                choseFile.map((file: any, index: number) => (
                  <div
                    key={index}
                    className='d-flex flex-col align-items-center justify--content-center gap-1 position-relative'
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      src={
                        typeof file === "string"
                          ? file?.split(".")?.pop()?.toLowerCase() === "pdf"
                            ? iconFile
                            : file
                          : file?.type === "application/pdf"
                          ? iconFile
                          : URL.createObjectURL(file)
                      }
                      onClick={() => setIndexReply(index)}
                      alt='inquiry'
                      style={{
                        width: "60px",
                        height: "60px",
                        cursor: "pointer",
                      }}
                    />
                    {((isEdit && inquiryDetail?.status == "2") || inquiryDetail?.status == "1") && (
                      <button
                        onClick={() => handleRemoveImage(index)}
                        type='button'
                        className='btn-close position-absolute bg-white'
                        aria-label='delete'
                        style={{
                          top: "1px",
                          right: "1px",
                          width: "5px",
                          height: "5px",
                          borderRadius: 0,
                        }}
                      ></button>
                    )}
                  </div>
                ))}
              {choseFile.length < 5 && (
                <div>
                  <label
                    htmlFor='file-upload'
                    className='d-flex flex-column align-items-center justify-content-center border border-dark'
                    style={{
                      width: "60px",
                      height: "60px",
                      cursor: !isEdit && inquiryDetail?.status == "2" ? "auto" : "pointer",
                    }}
                  >
                    <img src={photoSvg} alt='upload_photo' width={24} height={24} />
                    <p className='text-[#5F646F] fs-12 mb-0'>{choseFile.length}/5</p>
                  </label>

                  <input
                    id='file-upload'
                    type='file'
                    className='d-none'
                    accept={AcceptFileType.toString()}
                    multiple
                    onChange={handleUploadFile}
                    disabled={!isEdit && inquiryDetail?.status == "2"}
                  />
                </div>
              )}
            </div>
            {((isEdit && inquiryDetail?.status == "2") || inquiryDetail?.status == "1") && (
              <div className='w-50 mx-auto'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='checkbox_deduction_point'
                    id='checkbox_deduction_point'
                    checked={isDeductPoints}
                    onChange={(e) => {
                      setIsDeductPoints(e.target.checked);
                      if (!e.target.checked) {
                        setDeductPoints(0);
                      }
                    }}
                  />
                  <label className='form-check-label' htmlFor='checkbox_deduction_point'>
                    포인트 차감하기
                  </label>
                </div>
                <div className='position-relative w-100'>
                  <input
                    autoFocus
                    className='form-control'
                    id='deduction_point'
                    name='deduction_point'
                    value={deductPoints}
                    disabled={!isDeductPoints}
                    // defaultValue={"0"}
                    onChange={(event: any) => {
                      if (/^[0-9]*$/.test(event?.target?.value) || event?.target?.value === "") {
                        let value = event?.target?.value;
                        if (value.charAt(0) === "0") {
                          value = value.substring(1);
                        }
                        setDeductPoints(value);
                      }
                    }}
                    style={{
                      textAlign: "right",
                      paddingRight: "25px",
                    }}
                    maxLength={9}
                  />
                  <p
                    className='position-absolute'
                    style={{
                      top: 8,
                      right: 10,
                    }}
                  >
                    pt
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className='hstack gap-2 mt-3 justify-content-center'>
            {inquiryDetail?.status == "0" && (
              <button className='btn btn-primary fs-14 w-25' onClick={changeStatusToInProgress}>
                {t("inquiry.btn.reply")}
              </button>
            )}
            {inquiryDetail?.status == "1" && (
              <>
                <button
                  type='button'
                  className='btn btn-secondary fs-14'
                  onClick={() => {
                    showModal(
                      {
                        message: (
                          <div>
                            <p className='mb-2'>
                              {t(
                                "inquiry.If_you_cancel_your_registration_the_status_of_your_inquiry_will_change_to_Awaiting_Response"
                              )}
                            </p>
                            <p> {t("inquiry.Do_you_want_to_cancel_your_registration")}</p>
                          </div>
                        ),
                        title: t("inquiry.confirm"),
                        submitButtonText: t("inquiry.btn.confirm"),
                        cancelButtonText: t("inquiry.btn.cancel"),
                      },
                      async () => {
                        changeStatusToWaiting();
                      }
                    );
                  }}
                >
                  {t("inquiry.btn.cancel_registration")}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary fs-14'
                  onClick={() => submitReply()}
                >
                  {t("inquiry.btn.submit_response")}
                </button>
              </>
            )}
            {inquiryDetail?.status == "2" && (
              <>
                {isEdit ? (
                  <>
                    <button
                      type='button'
                      className='btn btn-secondary fs-14'
                      onClick={onCloseClick}
                    >
                      {t("inquiry.btn.cancel")}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary fs-14'
                      onClick={() => submitReply()}
                    >
                      {loadingReply ? <Spinner size='sm'></Spinner> : null}
                      {t("inquiry.btn.save")}
                    </button>
                  </>
                ) : (
                  <button
                    type='submit'
                    className='btn btn-primary fs-14'
                    onClick={() => {
                      setIsEdit(true);
                      if (inquiryDetail?.deduction_point > 0) {
                        setIsDeductPoints(true);
                        setDeductPoints(inquiryDetail?.deduction_point);
                      }
                    }}
                  >
                    {t("inquiry.btn.edit")}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalDetail;
