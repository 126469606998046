import { QueryParams } from "components/Hooks/useQueryParams";
import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/articles";
const articleManagementApi = {
  articles(dataForm: QueryParams) {
    return api.get(`${path}`, dataForm);
  },
  companies(dataForm: QueryParams) {
    return api.get(`${path}/companies`, dataForm);
  },
  articlesCategory(dataForm: QueryParams) {
    return api.get(`categories`, dataForm);
  },
};

export default articleManagementApi;
