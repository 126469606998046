import { createSlice } from "@reduxjs/toolkit";
import { ICoin, ICoinSearch } from "api/types/_coin";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getAllCompany } from "./thunk";

export interface IState {
  companies: (ResponseData<ICoinSearch[]> & PaginationResponse) | null;
  isCompanyLoading: boolean;
  isCompanySuccess: boolean;

  error: any;
}

export const initialState: IState = {
  companies: null,
  isCompanyLoading: false,
  isCompanySuccess: false,

  error: {},
};
// CompanyReducer
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get coins
    builder.addCase(getAllCompany.pending, (state: IState, action: any) => {
      state.isCompanyLoading = true;
    });
    builder.addCase(getAllCompany.fulfilled, (state: IState, action: any) => {
      state.companies = action.payload.data;
      state.isCompanySuccess = true;
      state.isCompanyLoading = false;
    });

    builder.addCase(getAllCompany.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCompanySuccess = false;
      state.isCompanyLoading = false;
    });
  },
});

export default companySlice.reducer;
