import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./store";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import AppNavigationProvider from 'components/Common/AppNavigationProvider';
import { ModalProvider } from 'context/confirm.context';

const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <ModalProvider>
    <React.Fragment>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AppNavigationProvider>
            <App />
          </AppNavigationProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </React.Fragment>
    </ModalProvider>
  </Provider>
);
reportWebVitals();