import React, { ReactNode } from "react";
interface LabelWrapperProps {
    children: ReactNode;
    label?: string;
    isShow?: boolean;
    disabled?: boolean;
    className?: string;
}

const LabelWrapper: React.FC<LabelWrapperProps> = ({
    children,
    label = '',
    isShow = false,
    disabled = false,
    className = '',
}) => {
    return (
        <div className={`position-relative ${className}`}>
            <>{children}</>
            {isShow && (<label style={{
                position: 'absolute',
                left: '5px',
                top: '0px',
                marginBottom: '0px',
                transform: 'translateY(-50%)',
                padding: `0px 5px`,
                color: '#c2c2c2',
                fontSize: '12px',
                fontWeight: '400',
                borderRadius: '3px',
                zIndex: 2,
                backgroundColor: disabled ? 'hsl(0, 0%, 95%)' : 'var(--vz-secondary-bg)',
            }}>
                {label}
            </label>)}
        </div>
    );
};

export default LabelWrapper;
