import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";

interface Props {
  open: boolean;
  onSubmit: MouseEventHandler<any> | undefined;
  onClose: MouseEventHandler<any> | undefined;
}

function ModalConfirmPoint({ open, onSubmit, onClose }: Props) {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={open}
      centered={true}
      scrollable={false}
      style={{ maxWidth: "300px", width: "100%" }}
    >
      <ModalBody className=''>
        <div>
          <div className='d-relative'>
            <h5 className='text-center w-100 mb-4'>{t("inquiry.btn.confirm")}</h5>
          </div>
          <div className='text-center'>
            <p style={{ whiteSpace: "pre-line" }}>{t("inquiry.do_not_enough_media_point")}</p>
            <p>{t("inquiry.would_you_like_to_register_your_answer_with_0p")}</p>
          </div>
          <div className='hstack gap-2 mt-3 justify-content-center'>
            <button type='button' className='btn btn-secondary fs-14' onClick={onClose}>
              {t("inquiry.btn.cancel")}
            </button>
            <button type='submit' className='btn btn-primary fs-14' onClick={onSubmit}>
              {t("inquiry.btn.confirm")}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalConfirmPoint;
