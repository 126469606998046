import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
import isURL from "validator/lib/isURL";
import { IPopupDTO, IresCmsPopup } from "api/types/_cmsHub";
import { useFormik } from "formik";
import moment from "moment";
import { catchAsync, formatWithKoreanAmPm } from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import ControlBox from "../Components/ControlBox";
import closeWhiteSvg from "../../../assets/images/cmshub/delete.svg";
import DateTimePickerCustom from "../Components/DateTimePicker";
import ButtonMediaAccess from "../Components/ButtonGotoMedia";
import { useModal } from "context/confirm.context";

interface Props {
  isOpen: boolean;
  id: number;
  company_id: number;
  onClose: () => void;
  refresh: () => void;
}
const ModalPopup = (props: Props) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const [isEdit, setIsEdit] = useState(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [choseFile, setChoseFile] = useState<any[]>([]);
  const [controlBoxPosition, setControlBoxPosition] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);
  const controlBoxRef = useRef<any>(null);
  const [detail, setDetail] = useState({} as IresCmsPopup);

  const POPUP_STATUS = {
    EXPRIED: 0,
    DISPLAY: 1,
    PERIOD_DISPLAY: 2,
  };

  const statusPopup = [
    {
      label: t("cms_media.exposure"),
      key: POPUP_STATUS.DISPLAY,
      bg: "#E1EBFF",
      color: "#1C6BFF",
    },
    {
      label: t("cms_media.period_exposure"),
      key: POPUP_STATUS.PERIOD_DISPLAY,
      bg: "#FFEEC2",
      color: "#FF8A1E",
    },
    {
      label: t("cms_media.end"),
      key: POPUP_STATUS.EXPRIED,
      bg: "#EBEEF1",
      color: "#5F646F",
    },
  ];

  const createPopupSchema = Yup.object().shape({
    title: Yup.string().max(20).required(""),
    status: Yup.number().required(""),
    valid_from: Yup.string().optional(),
    valid_to: Yup.string().optional(),
    link_url: Yup.string()
      .optional()
      .test(
        "is-url",
        t("cms_media.please_check_the_link_address_again"),
        function (value) {
          if (value?.length) {
            return isURL(value || "");
          }
          return true;
        }
      ),
    width: Yup.number().required(""),
    height: Yup.number().required(""),
    position_x: Yup.number(),
    position_y: Yup.number(),
    is_show_close: Yup.boolean(),
    is_show_close_today: Yup.boolean(),
  });

  const initialValues = {
    title: "",
    status: 1,
    valid_from: new Date().toISOString(),
    valid_to: new Date().toISOString(),
    link_url: "",
    width: 390,
    height: 390,
    position_x: 0,
    position_y: 0,
    is_show_close: true,
    is_show_close_today: true,
  } as IPopupDTO;

  const formik = useFormik({
    initialValues,
    validationSchema: createPopupSchema,
    onSubmit: async (values) => {
      if (values.width < 100) {
        values.width = 100;
      }
      if (values.width > 700) {
        values.width = 700;
      }
      if (values.height < 100) {
        values.height = 100;
      }
      if (formik.values.height > 700) {
        values.height = 700;
      }
      const [error, result] = await catchAsync(
        cmsHubMediaApi.cmshubUpdatePopups(
          values,
          choseFile,
          props.company_id,
          props.id
        ),
        setLoading
      );
      if (error) {
        console.log(error);
        return;
      }
      if (result.statusCode === 200) {
        props.onClose();
        setChoseFile([]);
        setAttachments([]);
        props.refresh();
        return;
      }
      if (result.errors) {
        formik.setErrors(result.errors || {});
      }
    },
  });

  const removeImage = (index: number) => {
    const imageTmp = structuredClone(choseFile);
    imageTmp.splice(index, 1);
    setChoseFile(imageTmp);
    setAttachments(
      imageTmp.map((item) =>
        typeof item.path === "string" ? item.path : URL.createObjectURL(item)
      )
    );
  };

  const onChangeWidthHeight = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    const value = e.target.value;
    if (value.match(/[^0-9]/)) {
      return;
    }
    formik.setFieldValue(key, value);
  };

  const isValidateButtonOnClose = () => {
    if (!formik.values.is_show_close && !formik.values.is_show_close_today) {
      return false;
    }
    return true;
  };

  const getPopup = async (id: number) => {
    const [error, result] = await catchAsync(
      cmsHubMediaApi.cmshubPopup(props.company_id, id),
      setLoading
    );
    if (error) {
      console.log(error);
      return;
    }
    const controlBoxParentRect =
      controlBoxRef.current.parentNode.getBoundingClientRect();
    const { attachments, ...data } = result.data;
    setDetail(data);
    setControlBoxPosition({
      x: parseFloat(data.position_x) * (controlBoxParentRect.width - 36),
      y: parseFloat(data.position_y) * (controlBoxParentRect.height - 36),
    });
    setChoseFile(attachments);
    setAttachments(attachments.map((item: any) => getUrl(item)));
    formik.setValues({
      ...data,
      valid_from: data.valid_from || new Date().toISOString(),
      valid_to: data.valid_to || new Date().toISOString(),
      link_url: data.link_url || "",
    });
  };

  const getUrl = (item: any) => {
    try {
      return !item.id ? URL.createObjectURL(item as any) : item.path;
    } catch {
      return "";
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files === null || event.target?.files?.length === 0) {
      return;
    }

    const allowedExtensions = ["jpg", "jpeg", "png", "svg", "gif"];

    let chosenFiles = Array.from(event.target.files).filter((file) => {
      const extension = file.name.split(".").pop()?.toLowerCase();
      return allowedExtensions.includes(extension!);
    });

    const totalFiles = chosenFiles.length + choseFile.length;

    if (totalFiles > 5) {
      const remainingSlots = 5 - choseFile.length;
      chosenFiles = chosenFiles.slice(0, remainingSlots);
    }

    const updatedChosenFiles = [...choseFile, ...chosenFiles];

    setChoseFile(updatedChosenFiles);
    setAttachments(
      updatedChosenFiles.map((item) =>
        typeof item.path === "string" ? item.path : URL.createObjectURL(item)
      )
    );

    (event.target as HTMLInputElement).value = "";
  };
  const isValidateDate = () => {
    const { valid_from, valid_to } = formik.values;
    if (valid_from && valid_to) {
      if (
        moment(valid_from).format("YYYY-MM-DD HH:mm") >
        moment(valid_to).format("YYYY-MM-DD HH:mm")
      ) {
        return false;
      }
    }
    return true;
  };

  const isCheckSubmit = () => {
    return (
      formik.dirty &&
      formik.isValid &&
      isValidateDate() &&
      choseFile?.length > 0 &&
      isValidateButtonOnClose()
    );
  };

  const renderRangeDate = (item: IresCmsPopup) => {
    return (
      <>
        {item.status === POPUP_STATUS.PERIOD_DISPLAY &&
          item.valid_to &&
          item.valid_from && (
            <div className="text-[12px] font-[400] text-[#5F646F]">
              <div>{formatWithKoreanAmPm(item.valid_from)}~</div>
              <div>{formatWithKoreanAmPm(item.valid_to)}</div>
            </div>
          )}
        {item.status === POPUP_STATUS.DISPLAY && (
          <div className="text-[12px] font-[400] text-[#5F646F]">
            {formatWithKoreanAmPm(item.created_at)}~
          </div>
        )}
        {item.status === POPUP_STATUS.EXPRIED && (
          <>
            {item.valid_to && item.valid_from ? (
              <div className="text-[12px] font-[400] text-[#5F646F]">
                <div>{formatWithKoreanAmPm(item.valid_from)}~</div>
                <div>{formatWithKoreanAmPm(item.valid_to)}</div>
              </div>
            ) : (
              <div className="text-[12px] font-[400] text-[#5F646F]">
                {formatWithKoreanAmPm(item.created_at)}~
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const onBlurSetValueWidthHeight = () => {
    if (formik.values.width < 100) {
      formik.setFieldValue("width", 100);
    }
    if (formik.values.width > 700) {
      formik.setFieldValue("width", 700);
    }
    if (formik.values.height < 100) {
      formik.setFieldValue("height", 100);
    }
    if (formik.values.height > 700) {
      formik.setFieldValue("height", 700);
    }
  };

  const handleDeletePopup = async () => {
    const [error, result] = await catchAsync(
      cmsHubMediaApi.cmshubDeletePopups(props.company_id,props.id),
      setLoading
    );
    if (error) {
      console.log(error);
      return;
    }
    if (result.statusCode === 200) {
      props.refresh();
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.id) {
      setIsEdit(false);
      getPopup(props.id);
    }
  }, [props.id, props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      centered={true}
      scrollable={false}
      style={{ width: "413px" }}
    >
      <div className="d-flex justify-content-between pt-3 pb-2 px-3 items-center">
        <div>
          <ButtonMediaAccess id={detail.company_id} redirect="/app/bulletin-board-management"/>
        </div>
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("cms_media.detailed_inquiry")}
        </div>
        <div onClick={() => props.onClose()}>
          <i
            className="ri-close-line"
            style={{ fontSize: "20px", cursor: "pointer" }}
          ></i>
        </div>
      </div>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2 align-items-center">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.media_name")}
              </div>
              <div>{detail.company_name}</div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.title")}
                {isEdit ? (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#FF5C46",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                ) : null}
              </div>
              {!isEdit ? (
                <div>{detail.title}</div>
              ) : (
                <Input
                  type="text"
                  placeholder={t("cms_media.please_enter_the_subject")}
                  {...formik.getFieldProps("title")}
                />
              )}
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.situation")}
                {isEdit ? (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#FF5C46",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                ) : null}
              </div>
              <div>
                {!isEdit ? (
                  <>
                    {
                      statusPopup.find((item) => item.key === detail.status)
                        ?.label
                    }
                  </>
                ) : (
                  <>
                    <div className="d-flex gap-4">
                      {statusPopup?.map((item, index: number) => (
                        <div
                          key={item?.key}
                          className="cursor-pointer table-hover-td-content-preview"
                          onClick={() => {
                            formik.setFieldValue("status", item?.key);
                          }}
                        >
                          <div>
                            <div
                              className="d-flex justify-content-between align-items-center form-check"
                              style={{
                                verticalAlign: "middle",
                                display: "inline-flex",
                              }}
                            >
                              <div className="d-flex justify-content-start">
                                <input
                                  className="form-check-input me-2"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={`flexRadioDefault${index}`}
                                  checked={
                                    item?.key ===
                                    formik.getFieldProps("status").value
                                  }
                                  style={{ minWidth: "16px" }}
                                />
                                <label
                                  className="form-check-label position-relative"
                                  htmlFor={`flexRadioDefault${index}`}
                                >
                                  {item?.label}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {isEdit && formik.values.status == POPUP_STATUS.PERIOD_DISPLAY && (
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <div style={{ minWidth: "70px" }}>{t("cms_media.start")}</div>
                  <DateTimePickerCustom
                    date={formik.values?.valid_from}
                    onChange={(date) =>
                      formik.setFieldValue("valid_from", date)
                    }
                  />
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div style={{ minWidth: "70px" }}>{t("cms_media.end")}</div>
                  <DateTimePickerCustom
                    date={isValidateDate() ? formik.values?.valid_to : null}
                    minDate={formik.values?.valid_from}
                    onChange={(date) => {
                      console.log(date)
                      formik.setFieldValue("valid_to", date)
                    }}
                  />
                </div>
              </div>
            )}
            {!isEdit && formik.values.status == POPUP_STATUS.PERIOD_DISPLAY ? (
              <div className="d-flex gap-2 align-items-center">
                <div className="fw-bold" style={{ minWidth: "70px" }}>
                  {t("cms_media.period")}
                </div>
                <div>{renderRangeDate(detail)}</div>
              </div>
            ) : null}
            <div className="d-flex gap-2">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.attachment")}
                {isEdit ? (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#FF5C46",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                ) : null}
              </div>
              <div className="d-flex flex-column gap-2">
                {isEdit ? (
                  <div className="w-100 d-flex gap-2">
                    <Button
                      type="button"
                      className="d-flex align-items-center justify-content-center"
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      <input
                        type="file"
                        multiple
                        accept=".jpg,.png,.jpeg,.svg,.gif"
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFileUpload(event)
                        }
                      />
                      <div>이미지 등록</div>
                    </Button>
                    <div>최대 5장까지 등록 가능합니다.</div>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {attachments.map((item, index: number) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        width: "67px",
                        height: "67px",
                      }}
                    >
                      <img
                        src={item}
                        alt=""
                        style={{
                          width: "67px",
                          height: "67px",
                          borderRadius: "4px",
                        }}
                      />
                      {isEdit ? (
                        <div
                          style={{
                            position: "absolute",
                            right: "0px",
                            top: "0px",
                            cursor: "pointer",
                            background: "#4C4E52",
                            flexShrink: "0",
                            padding: "4px",
                            borderRadius: "4px",
                          }}
                          onClick={() => removeImage(index)}
                        >
                          <img src={closeWhiteSvg} alt="remove" />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.popup_size")}
                {isEdit ? (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#FF5C46",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                ) : null}
              </div>
              <div>
                {!isEdit ? (
                  <>
                    W {detail.width} H {detail.height}
                  </>
                ) : (
                  <div className="d-flex gap-4 align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <div>W</div>
                      <Input
                        type="text"
                        placeholder={t("cms_media.please_enter_the_link")}
                        {...formik.getFieldProps("width")}
                        className="w-100"
                        onChange={(event) =>
                          onChangeWidthHeight(event, "width")
                        }
                        onBlur={onBlurSetValueWidthHeight}
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <div>H</div>
                      <Input
                        type="text"
                        placeholder={t("cms_media.please_enter_the_link")}
                        {...formik.getFieldProps("height")}
                        className="w-100"
                        onChange={(event) =>
                          onChangeWidthHeight(event, "height")
                        }
                        onBlur={onBlurSetValueWidthHeight}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.link_address")}
              </div>
              <div className="w-100">
                {!isEdit ? (
                  <div
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "calc(100% - 70px)",
                    }}
                  >
                    {detail.link_url || "-"}
                  </div>
                ) : (
                  <Input
                    type="text"
                    placeholder={t("cms_media.please_enter_the_link")}
                    {...formik.getFieldProps("link_url")}
                    className="w-100"
                  />
                )}
              </div>
            </div>
            <div
              className="d-flex gap-2"
              style={{ pointerEvents: !isEdit ? "none" : "initial" }}
            >
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.pop_up_location")}
                {isEdit ? (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#FF5C46",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                ) : null}
              </div>
              <ControlBox
                positionDefault={controlBoxPosition}
                controlBoxRef={controlBoxRef}
                onChangePosition={(position) => {
                  formik.setFieldValue("position_x", position.x);
                  formik.setFieldValue("position_y", position.y);
                }}
              />
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="fw-bold" style={{ minWidth: "70px" }}>
                {t("cms_media.close_area")}
                {isEdit ? (
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#FF5C46",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                ) : null}
              </div>
              <div className="d-flex gap-4 align-items-center">
                <div className="d-flex gap-2">
                  <Input
                    name="is_show_close"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="directMessage"
                    disabled={!isEdit}
                    checked={formik.values.is_show_close}
                    onChange={(e) => {
                      formik.setFieldValue("is_show_close", e.target.checked);
                    }}
                  />
                  <span>{t("cms_media.close")}</span>
                </div>
                <div className="d-flex gap-2">
                  <Input
                    name="is_show_close_today"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="directMessage"
                    disabled={!isEdit}
                    checked={formik.values.is_show_close_today}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "is_show_close_today",
                        e.target.checked
                      );
                    }}
                  />
                  <span>{t("cms_media.stop_to_day")}</span>
                </div>
              </div>
            </div>
            {isEdit ?  <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger fs-14"
                type="button"
                color="light"
                disabled={false}
                onClick={() => {
                  showModal(
                    {
                      message: (
                        <div>
                          {t('cms_media.are_you_sure_you_want_to_delete_the_pop_up')} <br />
                         {t('cms_media.if_the_pop-up_that_is_being_exposed_is_deleted_it_will_be_unexposed')}
                        </div>
                      ),
                      submitButtonText: "확인",
                    },
                    async () => {
                      handleDeletePopup();
                    }
                  );
                }}
              >
                {t("cms_media.delete")}
              </button>
            </div> : null}
            {isEdit ? (
              <div className="hstack gap-2 mt-3 justify-content-center px-5">
                <button
                  className="btn btn-soft-secondary fs-14 w-100"
                  type="button"
                  color="light"
                  onClick={() => props?.onClose()}
                  disabled={false}
                >
                  {t("cms_media.cancellation")}
                </button>
                <button
                  className="btn btn-primary fs-14 w-100"
                  type="submit"
                  disabled={isCheckSubmit() ? false : true}
                >
                  {loading ? <Spinner size="sm me-2"></Spinner> : null}
                  {t("cms_media.save")}
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center px-5">
                <button
                  className="btn btn-primary fs-14 w-100"
                  disabled={false}
                  type="button"
                  onClick={() => setIsEdit(true)}
                >
                  {t("cms_media.correction")}
                </button>
              </div>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ModalPopup;
