import React from "react";

interface FileViewerProps {
  src?: string;
  type?: string;
  width?: string | number;
  height?: string | number;
  borderRadius?: number;
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  backgroundColor?: string;
}

const FileViewer: React.FC<FileViewerProps> = ({
  src,
  width = 80,
  height = 80,
  type,
  borderRadius = 0,
  objectFit = "cover",
  backgroundColor = "",
}) => {
  const fileExtension = type ? type : src?.split(".")?.pop()?.toLowerCase();
  const isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtension || "");
  const isPdf = fileExtension === "pdf";
  const isVideo = ["mp4", "webm", "ogg"].includes(fileExtension || "");

  if (isImage) {
    return (
      <img
        src={src}
        alt='Image_file'
        style={{
          width,
          height,
          minWidth: width,
          minHeight: height,
          borderRadius,
          objectFit,
          backgroundColor,
        }}
      />
    );
  } else if (isPdf) {
    return (
      <iframe
        src={src}
        title='PDF'
        style={{
          width,
          height,
          minWidth: width,
          minHeight: height,
          overflow: "none",
          borderRadius,
        }}
      />
    );
  } else if (isVideo) {
    return (
      <video
        controls={false}
        autoPlay={false}
        style={{
          width,
          height,
          minWidth: width,
          minHeight: height,
          borderRadius,
        }}
      >
        <source src={src} type={`video/${fileExtension}`} />
        Your browser does not support the video tag.
      </video>
    );
  } else {
    return <div>Unsupported file format</div>;
  }
};

export default FileViewer;
